import classNames from 'classnames';
import { colorTypes } from '../components/types';

export const getErrorBody = (error: object | undefined): string | null => {
  if (
    error &&
    error['graphQLErrors'] &&
    error['graphQLErrors'][0] &&
    error['graphQLErrors'][0]['extensions'] &&
    error['graphQLErrors'][0]['extensions']['body']
  ) {
    return error['graphQLErrors'][0]['extensions']['body'];
  }
  return null;
};

export const getErrorMessage = (error: object | undefined): string | null => {
  if (
    error &&
    error['graphQLErrors'] &&
    error['graphQLErrors'][0] &&
    error['graphQLErrors'][0]['message']
  ) {
    return error['graphQLErrors'][0]['message'];
  }
  return null;
};

export const getErrorPath = (error: object | undefined): string | null => {
  if (
    error &&
    error['graphQLErrors'] &&
    error['graphQLErrors'][0] &&
    error['graphQLErrors'][0]['path']
  ) {
    return error['graphQLErrors'][0]['path'][0];
  }
  return null;
};

export const isDanger = (error: boolean | undefined): string =>
  classNames({ 'is-danger': error });

export const isColorDanger = (error: boolean | undefined): colorTypes =>
  error ? 'danger' : 'default';
