export const paymentMethods = {
  id: [
    {
      method: 'Kiosk',
      provider: 'midtrans',
      slug: 'kiosk',
      agents: ['ALFAMART', 'INDOMARET'],
      cardPayment: false,
    },
    {
      method: 'Bank Transfer',
      provider: 'twoctwop',
      slug: 'bank_transfer',
      agents: [
        'BNI',
        'MANDIRI',
        'PERMATA',
        'DANAMON',
        'CIMBVA',
        'BIIVA',
        'BANK_OTHER',
        'BCA',
        'BRI',
      ],
      cardPayment: false,
    },
    {
      method: 'E-Wallet',
      provider: 'midtrans',
      slug: 'ewallet',
      agents: ['GOPAY', 'SHOPEEPAY', 'QRIS'],
      cardPayment: false,
    },
    {
      method: 'Cardless Credit',
      provider: 'midtrans',
      slug: 'cardless_credit',
      agents: ['AKULAKU'],
      cardPayment: false,
    },
  ],
};

// Menu in the sidebar must be in the following order:
// Subscriptions, Orders, Activation Code
export const DashboardMenu = [
  {
    title: 'Subscriptions',
    slug: 'subscriptions',
    url: '/subscriptions',
  },
  {
    title: 'Orders',
    slug: 'orders',
    url: '/orders',
  },
  {
    title: 'Redeem Activation Code',
    slug: 'activation',
    url: '/activationcode',
  },
];

interface RegistrationFieldProps {
  required: boolean;
}

interface PhoneNumberFieldProps {
  required: boolean;
  minLength: number;
  maxLength: number;
}

interface RegistrationFields {
  gradeLevel: RegistrationFieldProps;
  phoneNumber: PhoneNumberFieldProps;
  email: RegistrationFieldProps;
  gender: RegistrationFieldProps;
  agreedToAgeConsent?: RegistrationFieldProps;
  agreedToTerms?: RegistrationFieldProps;
}

interface CountryForm {
  [key: string]: RegistrationFields;
}

export const countryForms: CountryForm = {
  id: {
    gradeLevel: {
      required: true,
    },
    phoneNumber: {
      required: true,
      minLength: 9,
      maxLength: 14,
    },
    gender: {
      required: false,
    },
    email: {
      required: false,
    },
    agreedToTerms: {
      required: true,
    },
  },
  ph: {
    gradeLevel: {
      required: true,
    },
    phoneNumber: {
      required: false,
      minLength: 7,
      maxLength: 10,
    },
    gender: {
      required: false,
    },
    email: {
      required: false,
    },
    agreedToAgeConsent: {
      required: true,
    },
    agreedToTerms: {
      required: true,
    },
  },
};

interface GradeLevel {
  [key: string]: string[];
}

export const gradeLevels: GradeLevel = {
  ph: [
    'over_g12',
    'g12',
    'g11',
    'g10',
    'g9',
    'g8',
    'g7',
    'g6',
    'g5',
    'g4',
    'g3',
    'g2',
    'g1',
    'kinder',
    'other',
  ],
  id: ['over_g12', 'g12', 'g11', 'g10', 'g9', 'g8', 'g7', 'other'],
};

type availableLanguages = 'en' | 'id';

type language = { [key in availableLanguages]: string };

export const languages: language = {
  en: 'English',
  id: 'Bahasa Indonesia',
};

export const getFinalAmountCents = (): number => {
  const discount: string = localStorage.getItem('discount') as string;

  const finalAmount = Boolean(discount)
    ? JSON.parse(discount).afterPrice
    : localStorage.getItem('pricingPlanPrice');

  const finalAmountCents = finalAmount.split('p')[1].split('.').join('');
  return parseInt(finalAmountCents);
};

export const getUrlParameter = (name: string): string => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  const results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1]);
};

const countryProperties = {
  ID: {
    currency: 'IDR',
  },
};

// Payment slip URL generator return payslip path
export const paymentSlipUrl = (orderId: string): string =>
  `${process.env.VIDEO_PAYMENT_API_URL}/api/orders/${orderId}/payment_slip`;

export const getCurrency = (countryCode: string): string => {
  return (
    (countryProperties[countryCode] &&
      countryProperties[countryCode]['currency']) ||
    'IDR'
  );
};

export const getDiscount = (key: string): string => {
  const discount = localStorage.getItem('discount');

  if (discount) {
    const parsedDiscount = JSON.parse(discount);
    return parsedDiscount[key];
  }

  return '';
};

export function getRefValue<K, T extends { value: K }>(
  ref: React.RefObject<T>,
): K | null {
  if (ref && ref.current) {
    return ref.current.value;
  }
  return null;
}

export const countryOptions = [
  {
    name: 'Indonesia',
    slug: 'indonesia',
    value: 'id',
  },
  {
    name: 'Philippines',
    slug: 'philippines',
    value: 'ph',
  },
];

export const clearCountdown = () => {
  localStorage.removeItem('countdownCounter');
};

export const isArrayContains = (arr1: string[], arr2: string[]): boolean => {
  if (arr1.length === 0 || arr2.length === 0) return false;
  if (!arr2.every((val) => arr1.includes(val))) return false;

  return true;
};

export const gradeVariants: GradeLevel = {
  id: ['g7', 'g8', 'g9', 'g10', 'g11', 'g12', 'over_g12', 'other'],
};
