import { isEmbed } from './queryParams';
import PushDataLayer, { PushDataLayerWithParams } from './DataLayer';

const gtmEvent = (label: string | object): void => {
  switch (typeof label) {
    case 'string':
      if (isEmbed()) PushDataLayer(`ga/android${label}`.toLowerCase());
      break;
    case 'object':
      if ('ecommerce' in label && label.ecommerce) {
        PushDataLayerWithParams({ ecommerce: null });
      }
      PushDataLayerWithParams(label);
      break;
  }
};

export default gtmEvent;
