import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Subtitle } from 'bloomer';
import Config from '../../config';
import BaseTemplate from '../templates/BaseTemplate';
import Footer from '../molecules/Footer';
import { Header } from '../molecules/header';
import Container from '../molecules/Container';
import ActionLink from '../organisms/ActionLink';

import './InvalidPage.scss';

const InvalidPage: React.FC<WrappedComponentProps> = ({
  intl,
}: WrappedComponentProps): React.ReactElement => {
  document.title = intl.formatMessage({
    id: 'page_title.other',
    defaultMessage: 'Quipper',
  });

  const renderHeader = (
    <div className="InvalidPage__header">
      <Header />
    </div>
  );
  const contentLink = intl.formatMessage(
    {
      id: 'dashboard.invalid.content_link',
      defaultMessage:
        'If you already have an active user account, please log in {login}',
    },
    {
      login: `<a href=${Config.learnUrl} className='link'> <strong> here </strong> </a>`,
    },
  );

  const renderBody = (
    <section className="InvalidPage__body">
      <Container isCompact>
        <ActionLink
          title={'dashboard.invalid.title'}
          defaultTitle={'The URL is invalid'}
          subtitle={'dashboard.invalid.content'}
          defaultSubtitle={
            'The link you are trying to access is invalid or it may already have expired. Please contact your School Admin for more information.'
          }
        >
          <Subtitle>
            <div dangerouslySetInnerHTML={{ __html: contentLink }} />
          </Subtitle>
        </ActionLink>
      </Container>
    </section>
  );

  return (
    <BaseTemplate
      className="InvalidPage"
      header={renderHeader}
      body={renderBody}
      footer={<Footer />}
    />
  );
};

export default injectIntl(InvalidPage);
