import * as React from 'react';

interface Props {
  className?: string;
  header?: React.ReactElement;
  body: React.ReactElement;
  footer?: React.ReactElement;
}

const PaymentTemplate = ({
  className,
  header,
  body,
  footer,
}: Props): React.ReactElement => (
  <div className={className && className}>
    {header && header}
    {body}
    {footer && footer}
  </div>
);

export default PaymentTemplate;
