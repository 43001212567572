import * as React from 'react';
import { Label } from 'bloomer';

import './Checkbox.scss';

interface Props {
  name: string;
  required: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({
  children,
  name,
  required,
  disabled,
  defaultChecked,
  onChangeHandler,
}: Props): React.ReactElement => {
  return (
    <Label className="checkbox-container">
      <input
        type="checkbox"
        name={name}
        checked={defaultChecked}
        disabled={disabled}
        required={required}
        onChange={onChangeHandler}
      />
      {children}
      <span className="checkmark" />
    </Label>
  );
};

export default Checkbox;
