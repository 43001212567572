import cloneDeep from 'lodash/cloneDeep';
import { FieldProps, defaultFieldProps, ValidationItemProps } from './props';

export interface CountryCodeProps {
  countryCode?: 'id' | 'ph';
}

export interface FacebookField {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  facebookToken?: string;
}

export interface GoogleField {
  googleId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  googleToken?: string;
}

export interface FieldsState {
  firstName: FieldProps;
  lastName: FieldProps;
  gradeLevel: FieldProps;
  phoneNumber: FieldProps;
  gender: FieldProps;
  email: FieldProps;
  uid: FieldProps;
  username: FieldProps;
  password: FieldProps;
  organizationMembershipCheck: FieldProps;
  organizationMembershipCode: FieldProps;
}

export interface QueryAttributes {
  firstName: string;
  lastName: string;
  gradeLevel: string;
  phoneNumber: string;
  gender: string;
  email: string;
  username: string;
  organizationMembershipCode: string;
  countryCode: 'id' | 'ph';
}

export interface RegistrationData {
  registrationData: QueryAttributes;
}

export interface DefaultValuesProps {
  defaultValues?: QueryAttributes | null;
}

export const defaultFieldsState = (): FieldsState => {
  return {
    firstName: cloneDeep(defaultFieldProps),
    lastName: cloneDeep(defaultFieldProps),
    gradeLevel: cloneDeep(defaultFieldProps),
    phoneNumber: cloneDeep(defaultFieldProps),
    gender: cloneDeep(defaultFieldProps),
    email: cloneDeep(defaultFieldProps),
    uid: cloneDeep(defaultFieldProps),
    username: cloneDeep(defaultFieldProps),
    password: cloneDeep(defaultFieldProps),
    organizationMembershipCheck: cloneDeep(defaultFieldProps),
    organizationMembershipCode: cloneDeep(defaultFieldProps),
  };
};

export const defaultFacebookFields = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  facebookToken: '',
};

export const defaultGoogleFields = {
  googleId: '',
  email: '',
  firstName: '',
  lastName: '',
  googleToken: '',
};

export interface ValidationItemInterface {
  letter: ValidationItemProps;
  number: ValidationItemProps;
  character: ValidationItemProps;
}

export interface ApiUserData {
  id?: string;
  accessToken?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  email?: string;
  username?: string;
  phoneNumber?: string;
  gradeLevel?: string;
  countryCode?: string;
}
