export enum VerificationStatus {
  Default,
  Success,
  Expired,
  Invalid,
}

export interface VerficationDataInterface {
  code: string;
  status: VerificationStatus;
}

export const VERIFICATION_DATA: VerficationDataInterface[] = [
  {
    code: '123ABC',
    status: VerificationStatus.Success,
  },
  {
    code: 'XYZ123',
    status: VerificationStatus.Expired,
  },
];
