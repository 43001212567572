import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import {
  WrappedComponentProps,
  IntlShape,
  injectIntl,
  FormattedMessage,
} from 'react-intl';

import { ApolloError } from 'apollo-client';
import { Mutation } from 'react-apollo';
import { navigate } from '@reach/router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CREATE_EMAIL_VERIFICATION } from '../../apollo/mutations/Verification';
import { CREATE_USER } from '../../apollo/mutations/User';
import { countryForms, gradeLevels } from '../../lib/Helpers';
import FacebookButton from '../../atoms/registrationPage/FacebookButton';
import GoogleButton from '../../atoms/registrationPage/GoogleButton';
import PasswordInput from '../../atoms/form/Input';
import Icon from '../../atoms/Icon';
import Tooltip from '../../atoms/Tooltip';
import Checkbox from '../../atoms/registrationPage/Checkbox';
import ValidationItem from '../../atoms/registrationPage/ValidationItem';

import ErrorMessage from '../../molecules/ErrorMessage';
import ValidationList from '../../molecules/registrationPage/ValidationList';

import { Config } from '../../../config';
import { cookies } from '../../lib/Storage';

import ErrorModal from './RegistrationFormRegistered';
import {
  CountryCodeProps,
  DefaultValuesProps,
  FacebookField,
  GoogleField,
  FieldsState,
  defaultFieldsState,
  defaultFacebookFields,
  defaultGoogleFields,
  ValidationItemInterface,
} from '../../../interfaces/Registration';
import { FieldProps } from '../../../interfaces/props';
import {
  v,
  FormValidator,
  isPresent,
  Nope,
  isIncludeNumber,
  isIncludeString,
  minLength,
} from '../../lib/Validator';
import {
  Button,
  Column,
  Columns,
  Control,
  Field,
  Label,
  Input,
  Select,
  Title,
  Tag,
} from 'bloomer';
import Browser from '../../../utils/Browser';
import gtmEvent from '../../../utils/GtmEvent';
import './RegistrationForm.scss';

import camelCase from 'lodash/camelCase';
import throttle from 'lodash/throttle';

import { isDanger } from '../../../utils/errorUtils';

const allValid = (prev: boolean, f: FieldProps): boolean => {
  return prev && isPresent(f.value).valid && f.valid;
};

const isValidationItemsValid = (
  validationItems: ValidationItemInterface,
): boolean =>
  Object.keys(validationItems).every((key) => validationItems[key].valid);

const tagSelector = (
  required: boolean,
  fields: FieldProps[],
  validationItems: ValidationItemInterface | undefined = undefined,
): string => {
  fields = [...fields];

  if (
    validationItems &&
    isValidationItemsValid(validationItems) &&
    fields.reduce(allValid, true)
  ) {
    return 'valid-tag';
  }

  if (fields.reduce(allValid, true) && !validationItems) {
    return 'valid-tag';
  }

  return required ? 'required-tag' : 'optional-tag';
};

const tagValue = (
  intl: IntlShape,
  required: boolean,
  fields: FieldProps[],
  validationItems: ValidationItemInterface | undefined = undefined,
): React.ReactNode => {
  fields = [...fields];

  if (
    validationItems &&
    isValidationItemsValid(validationItems) &&
    fields.reduce(allValid, true)
  ) {
    return <Icon icon="tick" />;
  }

  if (fields.reduce(allValid, true) && !validationItems) {
    return <Icon icon="tick" />;
  }

  const t = (id: string): string =>
    intl.formatMessage({
      id: `registration.form.validations.${id.toLowerCase()}`,
      defaultMessage: id,
    });

  return required ? t('Required') : t('Optional');
};

const RegistrationForm: React.FC<
  DefaultValuesProps & CountryCodeProps & WrappedComponentProps
> = ({
  countryCode = 'id',
  defaultValues,
  intl,
}: DefaultValuesProps &
  CountryCodeProps &
  WrappedComponentProps): React.ReactElement => {
  const refForm = useRef<HTMLFormElement>(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [consentChecked, setConsentChecked] = useState(false);
  const [fromLinks, setFromLinks] = useState<boolean>(false);
  const [registeredFrom, setRegisteredFrom] = useState<string>('subscribe');
  const [facebookProfile, setFacebookProfile] = useState<FacebookField>(
    defaultFacebookFields,
  );
  const [googleProfile, setGoogleProfile] =
    useState<GoogleField>(defaultGoogleFields);
  const [fields, setFields] = useState<FieldsState>(defaultFieldsState());
  const syncFields = (): void => {
    setFields((fields): FieldsState => ({ ...fields }));
  };
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [afterSubmit, setAfterSubmit] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [validationItems, setValidationItems] =
    useState<ValidationItemInterface>({
      letter: {
        title: 'Abc',
        description: intl.formatMessage({
          id: 'registration.form.account.guidelines.letter',
          defaultMessage: 'Letter',
        }),
        valid: false,
      },
      number: {
        title: '123',
        description: intl.formatMessage({
          id: 'registration.form.account.guidelines.number',
          defaultMessage: 'Number',
        }),
        valid: false,
      },
      character: {
        title: '8+',
        description: intl.formatMessage({
          id: 'registration.form.account.guidelines.character',
          defaultMessage: 'Character',
        }),
        valid: false,
      },
    });

  const syncValidationItems = (val: string): void => {
    const cloneValidationItems = Object.assign({}, validationItems);
    cloneValidationItems.letter.valid = !!val && isIncludeString(val).valid;
    cloneValidationItems.number.valid = !!val && isIncludeNumber(val).valid;
    cloneValidationItems.character.valid = !!val && minLength(8)(val).valid;

    setValidationItems(cloneValidationItems);
  };

  const nameFieldsClassName = (): string => {
    return cookies.get('language') === 'ja' ? 'is-reversed' : '';
  };
  const firstNameLabelClassNames = (): string => {
    return cookies.get('language') === 'ja'
      ? 'is-invisible is-hidden-mobile'
      : '';
  };
  const lastNameLabelClassNames = (): string => {
    return cookies.get('language') === 'ja'
      ? ''
      : 'is-invisible is-hidden-mobile';
  };
  const firstNameFieldClassName = (): string => {
    return cookies.get('language') === 'ja'
      ? 'first-name-field-ja'
      : 'first-name-field';
  };
  const lastNameFieldClassName = (): string => {
    return cookies.get('language') === 'ja'
      ? 'last-name-field-ja'
      : 'last-name-field';
  };
  const membershipClassName = (): string => {
    return !!defaultValues ? 'is-hidden' : '';
  };

  const cf = countryForms[countryCode];
  const y = (): Nope<FieldsState> => v<FieldsState>();

  // our validation schema
  const validationSchema = new FormValidator<FieldsState>({
    firstName: y()
      .variants(fromLinks.toString(), {
        true: y().isRequired(),
        false: y(),
      })
      .isValidName()
      .min(1)
      .max(20),
    lastName: y()
      .variants(fromLinks.toString(), {
        true: y().isRequired(),
        false: y(),
      })
      .isValidName()
      .min(1)
      .max(20),
    gradeLevel: y().isRequired(),
    phoneNumber: y()
      .variants(fromLinks.toString(), {
        true: y(),
        false: y(),
      })
      .trim()
      .isNumber()
      .min(cf.phoneNumber.minLength)
      .max(cf.phoneNumber.maxLength),
    email: y().isRequired().trim().toLowerCase().isValidEmail(),
    gender: y(),
    uid: y(),
    username: y()
      .variants(fromLinks.toString(), {
        true: y().isRequired(),
        false: y(),
      })
      .trim()
      .isValidUsername()
      .min(4)
      .max(20)
      .notContainedInRef('password'),
    password: y().isRequired().notContainingRef('username').max(64),
    organizationMembershipCheck: y(),
    organizationMembershipCode: y()
      .variantsRef('organizationMembershipCheck', {
        checked: y().isRequired(),
        _: y(),
      })
      .trim()
      .isNumber()
      .length(12),
  });

  const facebookCallback = (profile: FacebookField): void => {
    setFacebookProfile(profile);
    setGoogleProfile(defaultGoogleFields);
  };
  const googleCallback = (profile: GoogleField): void => {
    setGoogleProfile(profile);
    setFacebookProfile(defaultFacebookFields);
  };

  const handleOrganizationMembershipCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    fields.organizationMembershipCheck.value = e.target.checked
      ? 'checked'
      : '';

    if (!e.target.checked) {
      fields.organizationMembershipCode.value = '';
      fields.organizationMembershipCode.valid = true;
      validationSchema.validateField('organizationMembershipCode', '', fields);
    }

    syncFields();
  };

  const handleParentConsent = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setConsentChecked(e.target.checked);
  };

  const handleTermsAndCondition = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setTermsChecked(e.target.checked);
  };

  const validateForm = (fields: FieldsState): boolean => {
    let valid = true;

    valid =
      validationSchema.validateForm(fields) &&
      isValidationItemsValid(validationItems);

    syncFields();
    syncValidationItems(fields.password.value);

    return valid;
  };

  const handleChange = throttle(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = e.target;
      validationSchema.validateField(name, value, fields, {
        handleChange: true,
      });

      if (name === 'password') syncValidationItems(value);

      syncFields();
    },
    750,
  );

  const handleSubmit = (): void => {
    if (validateForm(fields) && refForm && refForm.current) {
      refForm.current.dispatchEvent(
        new Event('submit', {
          cancelable: true,
        }),
      );
      setAfterSubmit(true);
    }
  };

  const aggregateGraphqlErrors = (error?: ApolloError): void => {
    if (error === undefined) {
      return;
    }

    if (
      error &&
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].extensions &&
      error.graphQLErrors[0].extensions.body &&
      Array.isArray(error.graphQLErrors[0].extensions.body)
    ) {
      const serverErrors = error.graphQLErrors[0].extensions.body;
      serverErrors.forEach((error: object): void => {
        if (fields[camelCase(error['field'])]) {
          fields[camelCase(error['field'])].messages = [error['code']];
        }

        // for duplicate social account
        error['field'] === 'uid' &&
          error['code'] === 'has already been taken' &&
          setErrorModal(true);

        error['field'] === 'email' &&
          error['code'] === 'is_taken' &&
          setErrorModal(true);
      });
    }

    setFields((fields): FieldsState => {
      return { ...fields };
    });
  };

  const createAccount = intl.formatMessage({
    id: 'registration.submit_button',
    defaultMessage: 'Create Account',
  });

  const terms = intl.formatMessage({
    id: 'registration.agreement_notice.terms',
    defaultMessage: 'Terms',
  });

  const privacyPolicy = intl.formatMessage({
    id: 'registration.agreement_notice.privacy_policy',
    defaultMessage: 'Privacy Policy',
  });

  const termsURL = (): string => {
    if (countryCode == 'id') {
      return 'https://www.quipper.com/id/terms';
    } else {
      return 'https://www.quipper.com/en/terms';
    }
  };

  const privacyPolicyURL = (): string => {
    if (countryCode == 'id') {
      return 'https://www.quipper.com/id/privacy';
    } else {
      return 'https://www.quipper.com/en/privacy';
    }
  };

  const login = intl.formatMessage({
    id: 'registration.login',
    defaultMessage: 'Log In',
  });

  const androidUnsupported = intl.formatMessage({
    id: 'registration.unsupported.android',
    defaultMessage:
      'Your Android OS version is not supported. We strongly suggest upgrading your Android to the newest version of OS.',
  });

  const iosUnsupported = intl.formatMessage({
    id: 'registration.unsupported.ios',
    defaultMessage:
      'Your iOS version is not supported. We strongly suggest upgrading your device to the newest version of iOS.',
  });

  const checkUnsupportedDevice = (): React.ReactElement | null => {
    if (Browser.isAndroidVersionLessThan5()) {
      return (
        <div className="help is-danger unsupported-message-wrapper">
          {androidUnsupported}
        </div>
      );
    }

    if (Browser.isIOSVersionLessThan11()) {
      return (
        <div className="help is-danger unsupported-message-wrapper">
          {iosUnsupported}
        </div>
      );
    }
    return null;
  };

  const handleSocialAccountFields = (valid: boolean): void => {
    if (!valid) {
      fields['uid'].value = '';
      fields.password.valid = false;
      setTermsChecked(false);
    }

    fields.username.valid = valid;
    fields.gradeLevel.valid = valid;
    fields.phoneNumber.valid = valid;
    setConsentChecked(valid);
  };

  const handleErrorModal = (state: boolean): void => {
    if (!state) {
      setAfterSubmit(false);
      handleSocialAccountFields(false);
      syncFields();
    }

    setErrorModal(state);
  };

  useEffect((): void => {
    if (facebookProfile['email']) {
      setGoogleProfile(defaultGoogleFields);
      fields['firstName'].value = facebookProfile['firstName'] || '';
      fields['lastName'].value = facebookProfile['lastName'] || '';
      fields['email'].value = facebookProfile['email'] || '';
      fields['uid'].value = facebookProfile['facebookToken'] || '';
      validateForm(fields);
    }

    if (googleProfile['email']) {
      setFacebookProfile(defaultFacebookFields);
      fields['firstName'].value = googleProfile['firstName'] || '';
      fields['lastName'].value = googleProfile['lastName'] || '';
      fields['email'].value = googleProfile['email'] || '';
      fields['uid'].value = googleProfile['googleToken'] || '';
      validateForm(fields);
    }

    if (!!googleProfile['googleToken'] || !!facebookProfile['facebookToken']) {
      fields.password.valid = true;

      refForm &&
        refForm.current &&
        refForm.current.dispatchEvent(
          new Event('submit', {
            cancelable: true,
          }),
        );

      setAfterSubmit(true);
    }

    syncFields();
  }, [facebookProfile, googleProfile]);

  const hasSocialToken = (): boolean =>
    !!googleProfile['googleToken'] || !!facebookProfile['facebookToken'];

  useEffect((): void => {
    setTermsChecked(false);
    setConsentChecked(false);

    fields.phoneNumber.value = defaultValues?.phoneNumber || '';
    validateForm(fields);
  }, [countryCode]);

  useEffect((): void => {
    if (countryCode == 'ph') {
      if (consentChecked && termsChecked) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [consentChecked, termsChecked]);

  useEffect((): void => {
    if (countryCode == 'id') {
      if (termsChecked) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }, [termsChecked]);

  // Populate Form with Data from Query Params (Encrypted JSON String)
  useEffect((): void => {
    if (!!defaultValues) {
      const registrationData = defaultValues;
      fields.firstName.value = registrationData.firstName || '';
      fields.lastName.value = registrationData.lastName || '';
      fields.gradeLevel.value = registrationData.gradeLevel || '';
      fields.gender.value = registrationData.gender || '';
      fields.organizationMembershipCheck.value = 'checked';
      fields.organizationMembershipCode.value =
        registrationData.organizationMembershipCode || '';
      fields.phoneNumber.value = registrationData.phoneNumber || '';
      fields.email.value = registrationData.email || '';

      setRegisteredFrom('link_student');
      setFromLinks(true);
      syncFields();
    }
  }, [defaultValues]);

  useEffect(() => {
    validateForm(fields);
  }, [fromLinks]);

  const isCountryPhRender = (component: React.ReactElement) =>
    countryCode == 'ph' && component;
  const isCountryIdRender = (component: React.ReactElement) =>
    countryCode == 'id' && component;

  const hasSocialEmail = (email: string | undefined): string | null =>
    email ? fields.uid.value : null;

  const getGenderId = (genderValue: string): string =>
    genderValue
      ? `registration.form.account.genders.${genderValue}`
      : 'registration.form.account.placeholder.gender';
  const getGenderDefault = (genderValue: string): string =>
    genderValue ?? 'Select your gender';
  const getGradeId = (gradeValue: string): string =>
    gradeValue
      ? `registration.form.account.grades.${gradeValue}`
      : 'registration.form.account.placeholder.grade';
  const getGradeDefault = (gradeValue: string): string =>
    gradeValue ?? 'Select your grade';

  const insertTokenAndRedirect = (accessToken: string) => {
    accessToken &&
      cookies.set(Config.authTokenCookieName, accessToken, {
        path: '/',
        domain: Config.cookieDomain,
      });

    const previousUrl = cookies.get('previousUrl');
    const redirectUrl = previousUrl || Config.learnUrl;
    previousUrl && cookies.remove('previousUrl');

    window.location.assign(redirectUrl);
  };

  const renderOrganizationMembershipCheck = () =>
    fields.organizationMembershipCheck.value === 'checked' && (
      <Field
        className={isDanger(
          !!fields.organizationMembershipCode.messages.length,
        )}
      >
        <Control>
          <Input
            type="text"
            disabled={fromLinks}
            name="organizationMembershipCode"
            value={fields.organizationMembershipCode.value}
            maxLength={12}
            onChange={handleChange}
            placeholder={intl.formatMessage({
              id: 'registration.form.account.organization_membership_code.placeholder',
              defaultMessage: 'Enter 12-digit Number',
            })}
          />
          <ErrorMessage
            fieldName="organizationMembershipCode"
            fields={fields}
          />
        </Control>
      </Field>
    );

  const validateInput = (inputLength: number, inputValue: string): boolean =>
    !!(inputLength || (inputValue && !isValidationItemsValid(validationItems)));

  return (
    <Mutation
      mutation={CREATE_USER}
      onError={(): void => {
        /* empty handler to prevent thrown errors */
      }}
    >
      {(
        createUser: any,
        {
          loading: createUserLoading,
          error: createUserError,
          data: createUserData,
        }: any,
      ): React.ReactElement => {
        if (createUserLoading) {
          setSubmitLoading(true);
        }

        if (createUserError && afterSubmit) {
          setSubmitLoading(false);
          setAfterSubmit(false);
          aggregateGraphqlErrors(createUserError);
        }

        if (createUserData) {
          insertTokenAndRedirect(createUserData.createUser.accessToken);
        }

        return (
          <Mutation
            mutation={CREATE_EMAIL_VERIFICATION}
            onError={(): void => {
              /* empty handler to prevent thrown errors */
            }}
          >
            {(
              createEmailVerification: any,
              { loading, error, data }: any,
            ): React.ReactElement => {
              if (loading) {
                setSubmitLoading(true);
              }

              if (error && afterSubmit) {
                setSubmitLoading(false);
                setAfterSubmit(false);
                aggregateGraphqlErrors(error);
              }

              if (data) {
                gtmEvent({
                  event: 'signup',
                  method: 'email',
                });
                const { createEmailVerification: dataUser } = data;
                cookies.remove('loginReturnUrl', {
                  path: '/',
                  domain: Config.cookieDomain,
                  sameSite: 'lax',
                });

                navigate(`/verification`, {
                  state: {
                    password: fields.password.value,
                    email: dataUser.email,
                    grade: dataUser.grade,
                    registeredFrom: dataUser.registeredFrom,
                    countryCode: dataUser.countryCode,
                    organizationMembershipCode:
                      dataUser.organizationMembershipCode,
                  },
                });
              }

              const handlerSubmit = () => {
                hasSocialToken() || fromLinks
                  ? createUser({
                      variables: {
                        firstName: fields.firstName.value,
                        lastName: fields.lastName.value,
                        email: fields.email.value,
                        username: fields.username.value,
                        password: fields.password.value,
                        phoneNumber: fields.phoneNumber.value,
                        gradeLevel: fields.gradeLevel.value,
                        gender: fields.gender.value,
                        countryCode: countryCode,
                        facebookToken: hasSocialEmail(facebookProfile['email']),
                        googleToken: hasSocialEmail(googleProfile['email']),
                        organizationMembershipCode:
                          fields.organizationMembershipCode.value,
                        agreedToAgeConsent: consentChecked,
                        agreedToTerms: termsChecked,
                        registeredFrom: registeredFrom,
                      },
                    })
                  : createEmailVerification({
                      variables: {
                        email: fields.email.value,
                        password: fields.password.value,
                        grade: fields.gradeLevel.value,
                        countryCode: countryCode,
                        registeredFrom: registeredFrom,
                        organizationMembershipCode:
                          fields.organizationMembershipCode.value,
                      },
                    });
              };

              return (
                <section className="RegistrationForm">
                  <Columns>
                    <Column
                      className="form-column"
                      isSize={{ tablet: 10, desktop: 10, widescreen: 8 }}
                      isOffset={{ tablet: 1, desktop: 1, widescreen: 2 }}
                    >
                      <Title isSize={4}>
                        {intl.formatMessage({
                          id: 'registration.title',
                          defaultMessage: 'Create Account',
                        })}
                      </Title>
                      {checkUnsupportedDevice()}
                      {!fromLinks && (
                        <>
                          {/* Facebook */}
                          <FacebookButton
                            facebookCallback={(profile: FacebookField): void =>
                              facebookCallback(profile)
                            }
                          />
                          {/* Google */}
                          <GoogleOAuthProvider clientId={Config.googleClientId}>
                            <GoogleButton
                              googleCallback={(profile: {}): void =>
                                googleCallback(profile)
                              }
                            />
                          </GoogleOAuthProvider>

                          {/* Separator */}
                          <div className="line-separator">
                            <span className="text-separator">
                              {intl.formatMessage({
                                id: 'registration.or',
                                defaultMessage: 'OR',
                              })}
                            </span>
                          </div>
                        </>
                      )}

                      {/* Form */}
                      <form
                        ref={refForm}
                        noValidate={true}
                        onSubmit={(e): void => {
                          e.preventDefault();
                          handlerSubmit();
                        }}
                      >
                        {/* Name */}
                        {fromLinks && (
                          <>
                            <Columns className={nameFieldsClassName()}>
                              <Column isSize="1/2">
                                <Field
                                  className={`${firstNameFieldClassName()} ${isDanger(
                                    !!fields.firstName.messages.length,
                                  )}`}
                                >
                                  <Label
                                    htmlFor="firstName"
                                    className={firstNameLabelClassNames()}
                                  >
                                    <Tag
                                      className={tagSelector(true, [
                                        fields.firstName,
                                        fields.lastName,
                                      ])}
                                    >
                                      {tagValue(intl, true, [
                                        fields.firstName,
                                        fields.lastName,
                                      ])}
                                    </Tag>
                                    {intl.formatMessage({
                                      id: 'registration.form.account.title.name',
                                      defaultMessage: 'Name',
                                    })}
                                  </Label>
                                  <Control>
                                    <Input
                                      type="text"
                                      defaultValue={
                                        facebookProfile['firstName']
                                      }
                                      name="firstName"
                                      onChange={handleChange}
                                      maxLength={20}
                                      value={fields.firstName.value}
                                      required
                                      disabled={fromLinks}
                                      placeholder={intl.formatMessage({
                                        id: 'registration.form.account.placeholder.first_name',
                                        defaultMessage: 'First Name',
                                      })}
                                    />
                                  </Control>
                                </Field>
                              </Column>
                              <Column isSize="1/2">
                                <Field
                                  className={`${lastNameFieldClassName()} ${isDanger(
                                    !!fields.lastName.messages.length,
                                  )}`}
                                >
                                  <Label
                                    htmlFor="lastName"
                                    className={lastNameLabelClassNames()}
                                  >
                                    <Tag
                                      className={tagSelector(true, [
                                        fields.firstName,
                                        fields.lastName,
                                      ])}
                                    >
                                      {tagValue(intl, true, [
                                        fields.firstName,
                                        fields.lastName,
                                      ])}
                                    </Tag>
                                    {intl.formatMessage({
                                      id: 'registration.form.account.title.name',
                                      defaultMessage: 'Name',
                                    })}
                                  </Label>
                                  <Control>
                                    <Input
                                      type="text"
                                      defaultValue={facebookProfile['lastName']}
                                      name="lastName"
                                      onChange={handleChange}
                                      maxLength={20}
                                      value={fields.lastName.value}
                                      required
                                      disabled={fromLinks}
                                      placeholder={intl.formatMessage({
                                        id: 'registration.form.account.placeholder.last_name',
                                        defaultMessage: 'Last Name',
                                      })}
                                    />
                                  </Control>
                                </Field>
                              </Column>
                            </Columns>
                            <Columns>
                              <Column
                                isSize="full"
                                className="name-fields-error-messages"
                              >
                                <ErrorMessage
                                  fieldName="firstName, lastName"
                                  fields={fields}
                                />
                              </Column>
                            </Columns>
                          </>
                        )}

                        {/* Email */}
                        <Columns>
                          <Column>
                            <Field
                              className={isDanger(
                                !!fields.email.messages.length,
                              )}
                            >
                              <Label htmlFor="email">
                                <Tag
                                  className={tagSelector(true, [fields.email])}
                                >
                                  {tagValue(intl, true, [fields.email])}
                                </Tag>
                                {intl.formatMessage({
                                  id: 'registration.form.account.title.email',
                                  defaultMessage: 'Email',
                                })}
                              </Label>
                              <Control>
                                <Input
                                  type="text"
                                  disabled={fromLinks}
                                  defaultValue={facebookProfile['email']}
                                  name="email"
                                  required
                                  onChange={handleChange}
                                  value={fields.email.value}
                                  maxLength={45}
                                  placeholder={intl.formatMessage({
                                    id: 'registration.form.account.placeholder.email',
                                    defaultMessage: 'Enter your email',
                                  })}
                                />
                                <ErrorMessage
                                  fieldName="email"
                                  fields={fields}
                                />
                                <ErrorMessage fieldName="uid" fields={fields} />
                              </Control>
                            </Field>
                          </Column>
                        </Columns>

                        {/* Grade */}
                        <Columns>
                          <Column>
                            <Field
                              className={isDanger(
                                !!fields.gradeLevel.messages.length,
                              )}
                            >
                              <Label htmlFor="gradeLevel">
                                <Tag
                                  className={tagSelector(
                                    cf.gradeLevel.required,
                                    [fields.gradeLevel],
                                  )}
                                >
                                  {tagValue(intl, cf.gradeLevel.required, [
                                    fields.gradeLevel,
                                  ])}
                                </Tag>
                                {intl.formatMessage({
                                  id: 'registration.form.account.title.grade',
                                  defaultMessage: 'Grade',
                                })}
                              </Label>
                              <Control>
                                <Select
                                  disabled={fromLinks}
                                  name="gradeLevel"
                                  defaultValue=""
                                  isFullWidth
                                  required={cf.gradeLevel.required}
                                  onChange={handleChange}
                                >
                                  <option disabled hidden value="">
                                    {intl.formatMessage({
                                      id: getGradeId(fields.gradeLevel.value),
                                      defaultMessage: getGradeDefault(
                                        fields.gradeLevel.value,
                                      ),
                                    })}
                                  </option>
                                  {gradeLevels[countryCode].map(
                                    (grade, index): React.ReactElement => (
                                      <option key={index} value={grade}>
                                        {intl.formatMessage({
                                          id: `registration.form.account.grades.${grade}`,
                                          defaultMessage: grade,
                                        })}
                                      </option>
                                    ),
                                  )}
                                </Select>
                                <ErrorMessage
                                  fieldName="gradeLevel"
                                  fields={fields}
                                />
                              </Control>
                            </Field>
                          </Column>
                        </Columns>

                        {/* Gender */}
                        {fromLinks && (
                          <Columns>
                            <Column>
                              <Field
                                className={isDanger(
                                  !!fields.gender.messages.length,
                                )}
                              >
                                <Label htmlFor="gender">
                                  <Tag
                                    className={tagSelector(cf.gender.required, [
                                      fields.gender,
                                    ])}
                                  >
                                    {tagValue(intl, cf.gender.required, [
                                      fields.gender,
                                    ])}
                                  </Tag>
                                  {intl.formatMessage({
                                    id: 'registration.form.account.title.gender',
                                    defaultMessage: 'Gender',
                                  })}
                                </Label>
                                <Control>
                                  <Select
                                    name="gender"
                                    defaultValue=""
                                    isFullWidth
                                    required={cf.gender.required}
                                    onChange={handleChange}
                                  >
                                    <option disabled hidden value="">
                                      {intl.formatMessage({
                                        id: getGenderId(fields.gender.value),
                                        defaultMessage: getGenderDefault(
                                          fields.gender.value,
                                        ),
                                      })}
                                    </option>
                                    {['m', 'f'].map(
                                      (gender, index): React.ReactElement => (
                                        <option key={index} value={gender}>
                                          {intl.formatMessage({
                                            id: `registration.form.account.genders.${gender}`,
                                            defaultMessage:
                                              'MISSING_TRANSLATION',
                                          })}
                                        </option>
                                      ),
                                    )}
                                  </Select>
                                  <ErrorMessage
                                    fieldName="gender"
                                    fields={fields}
                                  />
                                </Control>
                              </Field>
                            </Column>
                          </Columns>
                        )}

                        {/* Phone */}
                        {fromLinks && (
                          <Columns>
                            <Column>
                              <Field
                                className={isDanger(
                                  !!fields.phoneNumber.messages.length,
                                )}
                              >
                                <Label htmlFor="phoneNumber">
                                  <Tag
                                    className={tagSelector(
                                      cf.phoneNumber.required,
                                      [fields.phoneNumber],
                                    )}
                                  >
                                    {tagValue(intl, cf.phoneNumber.required, [
                                      fields.phoneNumber,
                                    ])}
                                  </Tag>
                                  {intl.formatMessage({
                                    id: 'registration.form.account.title.phone',
                                    defaultMessage: 'Phone',
                                  })}
                                </Label>
                                <Control>
                                  <Input
                                    type="tel"
                                    name="phoneNumber"
                                    required={cf.phoneNumber.required}
                                    onChange={handleChange}
                                    value={fields.phoneNumber.value}
                                    maxLength={cf.phoneNumber.maxLength}
                                    placeholder={intl.formatMessage({
                                      id: 'registration.form.account.placeholder.phone',
                                      defaultMessage: 'Enter your phone number',
                                    })}
                                  />
                                  <ErrorMessage
                                    fieldName="phoneNumber"
                                    fields={fields}
                                    minLength={cf.phoneNumber.minLength}
                                    maxLength={cf.phoneNumber.maxLength}
                                  />
                                </Control>
                              </Field>
                            </Column>
                          </Columns>
                        )}

                        {/* Username */}
                        {fromLinks && (
                          <Columns>
                            <Column>
                              <Field
                                className={isDanger(
                                  !!fields.username.messages.length,
                                )}
                              >
                                <Label htmlFor="username">
                                  <Tag
                                    className={tagSelector(true, [
                                      fields.username,
                                    ])}
                                  >
                                    {tagValue(intl, true, [fields.username])}
                                  </Tag>
                                  {intl.formatMessage({
                                    id: 'registration.form.account.title.username',
                                    defaultMessage: 'Username',
                                  })}
                                </Label>
                                <Control>
                                  <Input
                                    type="text"
                                    name="username"
                                    onChange={handleChange}
                                    maxLength={20}
                                    value={fields.username.value}
                                    required
                                    placeholder={intl.formatMessage({
                                      id: 'registration.form.account.placeholder.username',
                                      defaultMessage: 'Enter your username',
                                    })}
                                  />
                                  <ErrorMessage
                                    fieldName="username"
                                    fields={fields}
                                  />
                                </Control>
                              </Field>
                            </Column>
                          </Columns>
                        )}

                        {/* Password */}
                        <Columns>
                          <Column>
                            <Field
                              className={isDanger(
                                validateInput(
                                  fields.password.messages.length,
                                  fields.password.value,
                                ),
                              )}
                            >
                              <Label htmlFor="password">
                                <Tag
                                  className={tagSelector(
                                    true,
                                    [fields.password],
                                    validationItems,
                                  )}
                                >
                                  {tagValue(
                                    intl,
                                    true,
                                    [fields.password],
                                    validationItems,
                                  )}
                                </Tag>
                                {intl.formatMessage({
                                  id: 'registration.form.account.title.password',
                                  defaultMessage: 'Password',
                                })}
                              </Label>
                              <Control>
                                <PasswordInput
                                  className="input"
                                  type="password"
                                  name="password"
                                  onChange={handleChange}
                                  required
                                  placeholder={intl.formatMessage({
                                    id: 'registration.form.account.placeholder.password',
                                    defaultMessage: 'Enter your password',
                                  })}
                                  maxLength={64}
                                />
                                <ErrorMessage
                                  fieldName="password"
                                  fields={fields}
                                />
                              </Control>
                            </Field>
                            <ValidationList
                              title={intl.formatMessage({
                                id: 'registration.form.account.guidelines.title',
                                defaultMessage: 'Password has to include :',
                              })}
                            >
                              {Object.keys(validationItems).map((key) => (
                                <React.Fragment key={validationItems[key].id}>
                                  <ValidationItem {...validationItems[key]} />
                                </React.Fragment>
                              ))}
                            </ValidationList>
                          </Column>
                        </Columns>

                        {/* MembershipCode */}
                        <Columns className={membershipClassName()}>
                          <Column>
                            <Field>
                              <Control>
                                <Checkbox
                                  disabled={fromLinks}
                                  defaultChecked={
                                    fields.organizationMembershipCheck.value ==
                                    'checked'
                                  }
                                  name="membership-checkbox"
                                  required={false}
                                  onChangeHandler={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ): void =>
                                    handleOrganizationMembershipCheck(e)
                                  }
                                >
                                  <span className="membership-code-label">
                                    {intl.formatMessage({
                                      id: 'registration.form.account.organization_membership_code.checkbox',
                                      defaultMessage:
                                        'I have a School Membership Number',
                                    })}
                                  </span>
                                  <Tooltip
                                    text={intl.formatMessage({
                                      id: 'registration.form.account.organization_membership_code.annotation',
                                      defaultMessage:
                                        'School Membership Number is the 12-digit number given by your teacher.',
                                    })}
                                  >
                                    <Icon icon="info" />
                                  </Tooltip>
                                </Checkbox>
                              </Control>
                            </Field>
                            {renderOrganizationMembershipCheck()}
                          </Column>
                        </Columns>

                        {/* [PH] Age Consent and Terms & Conditions */}
                        {isCountryPhRender(
                          <Columns>
                            <Column>
                              <Field>
                                <Control className="small">
                                  <Checkbox
                                    name="agreedToAgeConsent"
                                    required={true}
                                    onChangeHandler={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ): void => handleParentConsent(e)}
                                  >
                                    <span>
                                      {intl.formatMessage({
                                        id: 'registration.form.age_consent',
                                        defaultMessage:
                                          'You confirm that you’re 18 years old or older or giving this consent on behalf of an individual below 18 years old. Further, you confirm that you are legally capable of providing this consent under applicable laws and regulation.',
                                      })}
                                    </span>
                                  </Checkbox>
                                </Control>
                              </Field>
                              <Field>
                                <Control className="small">
                                  <Checkbox
                                    name="agreedToTerms"
                                    required={true}
                                    onChangeHandler={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ): void => handleTermsAndCondition(e)}
                                  >
                                    <FormattedMessage
                                      id="registration.form.terms_conditions"
                                      defaultMessage="You have read, understood, and agree to our {terms} and {policy}."
                                      values={{
                                        terms: (
                                          <a
                                            className="link"
                                            href={termsURL()}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {terms}
                                          </a>
                                        ),
                                        policy: (
                                          <a
                                            className="link"
                                            href={privacyPolicyURL()}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {privacyPolicy}
                                          </a>
                                        ),
                                      }}
                                    />
                                  </Checkbox>
                                </Control>
                              </Field>
                            </Column>
                          </Columns>,
                        )}

                        {/* [ID] Terms & Conditions */}
                        {isCountryIdRender(
                          <Columns>
                            <Column>
                              <Field>
                                <Control className="small">
                                  <Checkbox
                                    name="agreedToTerms"
                                    required={!hasSocialToken}
                                    onChangeHandler={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ): void => handleTermsAndCondition(e)}
                                  >
                                    <FormattedMessage
                                      id="registration.form.terms_conditions_for_indonesia"
                                      defaultMessage="I agree to Quipper’s {terms} and {policy}. This approval must be given by your parent if you are a minor."
                                      values={{
                                        terms: (
                                          <a
                                            className="link"
                                            href={termsURL()}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {terms}
                                          </a>
                                        ),
                                        policy: (
                                          <a
                                            className="link"
                                            href={privacyPolicyURL()}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {privacyPolicy}
                                          </a>
                                        ),
                                      }}
                                    />
                                  </Checkbox>
                                </Control>
                              </Field>
                            </Column>
                          </Columns>,
                        )}

                        {/* Submit */}
                        <Columns>
                          <Column>
                            <Field>
                              <Control>
                                <Button
                                  isColor="info"
                                  isFullWidth
                                  isLoading={submitLoading}
                                  onClick={handleSubmit}
                                  name="submit"
                                  className="submit-btn"
                                  disabled={disableSubmit}
                                >
                                  {createAccount}
                                </Button>
                              </Control>
                            </Field>
                          </Column>
                        </Columns>
                      </form>

                      {/* Login */}
                      <hr />
                      <p className="login">
                        <FormattedMessage
                          id="registration.already_have_account"
                          defaultMessage="If you already have an account, {login} instead."
                          values={{
                            login: (
                              <a href={Config.learnUrl} className="link">
                                {login}
                              </a>
                            ),
                          }}
                        />
                      </p>
                    </Column>
                  </Columns>
                  <ErrorModal
                    isActive={errorModal}
                    onClose={(state): void => handleErrorModal(state)}
                  />
                </section>
              );
            }}
          </Mutation>
        );
      }}
    </Mutation>
  );
};

export default injectIntl(RegistrationForm);
