import gql from 'graphql-tag';

export const CREATE_EMAIL_VERIFICATION = gql`
  mutation CreateEmailVerification(
    $email: String!
    $password: String!
    $grade: String!
    $registeredFrom: String!
    $countryCode: String!
    $organizationMembershipCode: String!
  ) {
    createEmailVerification(
      email: $email
      password: $password
      grade: $grade
      registeredFrom: $registeredFrom
      countryCode: $countryCode
      organizationMembershipCode: $organizationMembershipCode
    ) {
      email
      grade
      registeredFrom
      countryCode
      organizationMembershipCode
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($code: String, $email: String, $token: String) {
    verifyEmail(code: $code, email: $email, token: $token) {
      accessToken
      userId
      expiresAt
      redirectUrl
    }
  }
`;
