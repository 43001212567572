import * as React from 'react';
import { Title } from 'bloomer';
import classNames from 'classnames';

import Icon from '../Icon';

import './ValidationItem.scss';

import { ValidationItemProps } from '../../../interfaces/props';

const ValidationItem = ({ title, description, valid }: ValidationItemProps) => {
  const validationItemClassNames = classNames([
    'validation-item',
    { 'validation-item--valid': valid },
  ]);

  return (
    <div className={validationItemClassNames}>
      <div className="validation-item__status">
        {valid ? (
          <Icon icon="checklist" />
        ) : (
          <Title tag="h6" isSize={6} className="validation-item__title">
            {title}
          </Title>
        )}
      </div>

      <p className="validation-item__description">{description}</p>
    </div>
  );
};

export default ValidationItem;
