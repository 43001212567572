import * as React from 'react';
import ApiClient from '../../apollo/ApiClient';
import { GET_GOOGLE_ID_TOKEN } from '../../apollo/queries/GoogleIdToken';
import { useState } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { GoogleField } from '../../../interfaces/Registration';
import { Button } from 'bloomer';
import { useGoogleLogin } from '@react-oauth/google';
import Icon from '../../atoms/Icon';

import './GoogleButton.scss';

import gtmEvent from '../../../utils/GtmEvent';

interface GoogleButtonProps {
  googleCallback: (profile: GoogleField) => void;
}

const GoogleButton: React.FC<GoogleButtonProps & WrappedComponentProps> = ({
  googleCallback,
  intl,
}: GoogleButtonProps & WrappedComponentProps): React.ReactElement => {
  const [googleError, setGoogleError] = useState(false);
  const signIn = useGoogleLogin({
    onSuccess: (res: {}) => {
      ApiClient.query({
        query: GET_GOOGLE_ID_TOKEN,
        variables: {
          code: res['code'],
          redirectUri: window.location.origin,
        },
      }).then((result: any): void => {
        const { googleIdToken } = result.data;
        const decodedResponse = JSON.parse(
          atob(googleIdToken.idToken.split('.')[1]),
        );
        googleCallback({
          googleId: decodedResponse['sub'],
          firstName: decodedResponse['given_name'],
          lastName: decodedResponse['name'],
          email: decodedResponse['email'],
          googleToken: googleIdToken.idToken,
        });
        gtmEvent({
          event: 'signup',
          method: 'google',
        });
      });
    },
    onError: (errorResponse: {}) => {
      console.log(errorResponse);
      if (errorResponse['error'] == 'popup_closed_by_user') {
        return;
      } else {
        setGoogleError(true);
      }
    },
    flow: 'auth-code',
  });

  if (googleError) {
    return (
      <div className="google-error">
        <p>
          {intl.formatMessage({
            id: 'registration.google.error',
            defaultMessage: 'Sorry, Google is currently unavailable.',
          })}
        </p>
      </div>
    );
  }

  return (
    <Button
      className="google-button"
      isFullWidth
      onClick={(): void => {
        signIn();
      }}
    >
      <Icon icon="google" />
      {intl.formatMessage({
        id: 'registration.google.signup_button',
        defaultMessage: 'Sign Up with Google',
      })}
    </Button>
  );
};

export default injectIntl(GoogleButton);
