import { FieldProps } from '../../interfaces/props';
import cloneDeep from 'lodash/cloneDeep';
import { format } from 'date-fns';

interface Validator {
  valid: boolean;
  value: string;
  error?: string;
}
export interface CardExpDate {
  month: string;
  year: string;
}

type FormField = string | CardExpDate;

type FieldsStateType<T> = { [P in keyof T]: FieldProps };

type FuncValidator<T> = (
  val: FormField,
  fields?: FieldsStateType<T>,
) => Validator;

export const isPresent = (val: string): Validator => {
  return {
    valid: !!val.trim(),
    value: val,
    error: 'not_present',
  };
};

// Previously we allow number in names,
// assuming we will disallow number from now on,
// the rules:
// - allow space
//   but space cleanup should be done outside this function
//   will need trim, and squeeze
//
// - single quote (') is a valid part of name.
//   e.g. Conan O'brien
//
// - name can has a hyphen (-)
//   e.g. Jukka-Pekka
//
// - allow supplemental char for latin-1
//   e.g. Kevin ßáçøñ
//
// - allow hiragana, katakana, and kanji for both zenkaku and hankaku
//   e.g. みゃーもり (supposed to be 宮森 though)
export const isValidName = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(
      /^[a-zA-Z \u00c0-\u00ff\'\-々ぁ-ゔゞァ-・ヽヾ゛゜ー\u4e00-\u9faf]+$/,
    ),
    value: val,
    error: 'invalid',
  };
};

export const isRequired = <T>(variant: boolean): FuncValidator<T> => {
  return (val: string): Validator => {
    if (!variant) {
      return { valid: true, value: val };
    }
    return {
      valid: isPresent(val).valid,
      value: val,
      error: 'required',
    };
  };
};

export const isRequiredIfField = <T>(
  name: string,
  value: string,
): FuncValidator<T> => {
  return (val: string, fields: FieldsStateType<T>): Validator => {
    return fields[name].value === value
      ? isRequired(true)(val)
      : { valid: true, value: val };
  };
};

export const isValidEmail = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(
      /^(?!.*?[.]{2})[a-z0-9]+[a-z0-9._%\-+]*[a-z0-9]@[a-z0-9]+[a-z0-9.-]*[a-z0-9]*\.[a-z]{2,24}$/,
    ),
    value: val,
    error: 'invalid',
  };
};

// allow letters, numbers, dash '-', underscore '_', dot'.'
// (only allow lowercase)
export const isValidUsername = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(/^[a-z0-9\-_\.]+$/),
    value: val,
    error: 'invalid',
  };
};

export const isValidPassword = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(/\d/) && !!val.match(/[a-zA-Z]/),
    value: val,
    error: 'invalid',
  };
};

export const isIncludeNumber = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(/\d/) || !!val.match(/\d+/g),
    value: val,
    error: 'not_include_number',
  };
};

export const isIncludeString = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(/[a-zA-Z]/) || !!val.match(/.*\\d.*/),
    value: val,
    error: 'not_include_string',
  };
};

export const isNumber = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(/^\d+$/),
    value: val,
    error: 'invalid',
  };
};

export const isStringOrNumber = (val: string): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: !!val.match(/^[0-9a-zA-Z]+$/),
    value: val,
    error: 'invalid',
  };
};

export const isCardNumber = (val: string): Validator => {
  return {
    valid: val.length === 16,
    value: val,
    error: 'invalid',
  };
};

export const isValidCCV = (val: string): Validator => {
  return {
    valid: val.length === 3 || val.length === 4,
    value: val,
    error: 'invalid',
  };
};

export const cardIsNotExpired = ({ month, year }: CardExpDate): Validator => {
  const today = new Date();
  const suffixYear = today.getFullYear().toString().substr(0, 2);
  const expday = new Date(
    `${suffixYear}${year}/${month}/${format(today, 'd')}`,
  );
  today.setHours(0, 0, 0, 0);
  expday.setHours(0, 0, 0, 0);

  return {
    valid: expday >= today,
    value: `${month}/${year}`,
    error: 'invalid',
  };
};

const validLengthChecker = (
  val: string,
  minLength: number,
  maxLength: number,
): Validator => {
  if (!isPresent(val).valid) {
    return { valid: true, value: val };
  }
  return {
    valid: val.length >= minLength && val.length <= maxLength,
    value: val,
    error: 'invalid_length',
  };
};

export const validLength = <T>(min: number, max?: number): FuncValidator<T> => {
  return (val: string): Validator => {
    if (!isPresent(val).valid) {
      return { valid: true, value: val };
    }
    if (max === undefined) {
      return {
        valid: val.length === min,
        value: val,
        error: 'invalid_length',
      };
    }
    return validLengthChecker(val, min, max);
  };
};

export const minLength = <T>(min: number): FuncValidator<T> => {
  return (val: string): Validator => {
    if (!isPresent(val).valid) {
      return { valid: true, value: val };
    }
    return {
      valid: val.length >= min,
      value: val,
      error: 'invalid_length',
    };
  };
};

export const maxLength = <T>(max: number): FuncValidator<T> => {
  return (val: string): Validator => {
    if (!isPresent(val).valid) {
      return { valid: true, value: val };
    }
    return {
      valid: val.length <= max,
      value: val,
      error: 'invalid_length',
    };
  };
};

export const matchWithRef = <T>(name: string): FuncValidator<T> => {
  return (val: string, fields: FieldsStateType<T>): Validator => {
    if (!isPresent(val).valid) {
      return { valid: true, value: val };
    }
    return {
      valid: val === fields[name].value,
      value: val,
      error: 'not_matched',
    };
  };
};

export const notContainingRef = <T>(name: string): FuncValidator<T> => {
  return (val: string, fields: FieldsStateType<T>): Validator => {
    if (!isPresent(fields[name].value).valid) {
      return { valid: true, value: val };
    }
    const v1 = fields[name].value.toLowerCase();
    const v2 = val.toLowerCase();
    return {
      valid: !v2.includes(v1),
      value: val,
      error: 'contained',
    };
  };
};

export const notContainedInRef = <T>(name: string): FuncValidator<T> => {
  return (val: string, fields: FieldsStateType<T>): Validator => {
    if (!isPresent(val).valid) {
      return { valid: true, value: val };
    }
    const v1 = fields[name].value.toLowerCase();
    const v2 = val.toLowerCase();
    return {
      valid: !v1.includes(v2),
      value: val,
      error: 'contained',
    };
  };
};

export const toLowerCase = (val: string): Validator => {
  return { valid: true, value: val.toLowerCase() };
};

export const trim = (val: string): Validator => {
  return { valid: true, value: val.trim() };
};

export const runValidationsFor = (
  fieldName: string,
  value: FormField,
  validationSchema: object,
): Validator[] => {
  return validationSchema[fieldName].map(
    (validation: (value: FormField) => Validator): Validator => {
      return validation(value || '');
    },
  );
};

export const areRulesComplied = (validations: Validator[]): boolean => {
  const areComplied = !validations
    .map((result: Validator): boolean => {
      return result.valid;
    })
    .includes(false);

  return areComplied;
};

interface ValidationSchema<T> {
  [key: string]: Nope<T>;
}

export class FormValidator<T> {
  private schema: ValidationSchema<T>;

  public constructor(schema: ValidationSchema<T>) {
    this.schema = schema;
  }

  public validateField(
    name: string,
    value: string,
    fields: FieldsStateType<T>,
    opts?: { handleChange: boolean },
  ): boolean {
    const schema: FuncValidator<T>[] = this.schema[name].$();
    let result: Validator = { valid: true, error: '', value: value };

    for (const validate of schema) {
      result = validate(result.value, fields);

      if (!result.valid) {
        break;
      }
    }

    fields[name] = {
      value: result.value,
      valid: !!result.valid,
      messages: [],
    };

    let extracheck = true;
    if (opts && opts.handleChange) {
      extracheck = isPresent(fields[name].value).valid;
    }

    // decide if we need to assign error here.
    // i.e. if field is empty and we're not handling change event,
    // then there's no need to assign error.
    if (extracheck && !result.valid && result.error) {
      fields[name].messages = [result.error];
    }

    return !!result.valid;
  }

  public validateForm(fields: FieldsStateType<T>): boolean {
    let allValid = true;
    for (const name in fields) {
      const isValid = this.validateField(name, fields[name].value, fields);
      allValid = allValid && isValid;
    }
    return allValid;
  }
}

export class Nope<T> {
  private validators: FuncValidator<T>[];

  public constructor() {
    this.validators = [];
  }

  public isRequired(): Nope<T> {
    this.validators.push(isRequired(true));
    return this;
  }

  public isValidName(): Nope<T> {
    this.validators.push(isValidName);
    return this;
  }

  public min(n: number): Nope<T> {
    this.validators.push(minLength(n));
    return this;
  }

  public max(n: number): Nope<T> {
    this.validators.push(maxLength(n));
    return this;
  }

  public isNumber(): Nope<T> {
    this.validators.push(isNumber);
    return this;
  }

  public isValidEmail(): Nope<T> {
    this.validators.push(isValidEmail);
    return this;
  }

  public isValidUsername(): Nope<T> {
    this.validators.push(isValidUsername);
    return this;
  }

  public isValidPassword(): Nope<T> {
    this.validators.push(isValidPassword);
    return this;
  }

  public isIncludeNumber(): Nope<T> {
    this.validators.push(isIncludeNumber);
    return this;
  }

  public isIncludeString(): Nope<T> {
    this.validators.push(isIncludeString);
    return this;
  }

  public length(len: number): Nope<T> {
    this.validators.push(validLength(len));
    return this;
  }

  public notContainedInRef(field: string): Nope<T> {
    this.validators.push(notContainedInRef(field));
    return this;
  }

  public notContainingRef(field: string): Nope<T> {
    this.validators.push(notContainingRef(field));
    return this;
  }

  public matchWithRef(field: string): Nope<T> {
    this.validators.push(matchWithRef(field));
    return this;
  }

  public trim(): Nope<T> {
    this.validators.push(trim);
    return this;
  }

  public toLowerCase(): Nope<T> {
    this.validators.push(toLowerCase);
    return this;
  }

  public variants(value: string, alts: ValidationSchema<T>): Nope<T> {
    const select = alts[value] || alts['_'];
    if (!select) {
      throw Error(
        `Variants not exhaustive, looking for ${value} or wildcard "_"`,
      );
    }
    this.validators = this.validators.concat(select.$());
    return this;
  }

  public variantsRef(field: string, alts: ValidationSchema<T>): Nope<T> {
    const setVariants = (
      val: string,
      fields: FieldsStateType<T>,
    ): Validator => {
      const select = alts[fields[field].value] || alts['_'];
      if (!select) {
        throw Error(
          `Variants not exhaustive, looking for ${fields[field].value} or wildcard "_"`,
        );
      }

      const validator = new FormValidator<T & { _: FieldProps }>({
        _: select,
      });

      const inFields: FieldsStateType<T & { _: FieldProps }> = Object.assign(
        {
          _: {
            value: val,
            valid: true,
            messages: [],
          },
        },
        cloneDeep(fields),
      );

      return {
        valid: validator.validateField('_', val, inFields),
        value: inFields._.value,
        error: inFields._.messages[0],
      };
    };

    this.validators.push(setVariants);
    return this;
  }

  public $(): FuncValidator<T>[] {
    return this.validators;
  }

  public isStringOrNumber(): Nope<T> {
    this.validators.push(isStringOrNumber);
    return this;
  }
}

export const v = <T>(): Nope<T> => new Nope<T>();
