import * as React from 'react';
import BrandFooter from '../../assets/images/brand_footer.svg';
import Container from '../molecules/Container';
import LanguageSelector from './LanguageSelector';
import {
  Level,
  LevelLeft,
  LevelRight,
  LevelItem,
  Footer as BaseFooter,
} from 'bloomer';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import './Footer.scss';

const currentYear = new Date().getFullYear();

const Footer: React.FC<WrappedComponentProps> = ({
  intl,
}: WrappedComponentProps): React.ReactElement => {
  const userCountryCode = document.location.pathname.includes('id')
    ? 'id'
    : 'en';
  const termsAndConditions = intl.formatMessage({
    id: 'footer.terms_and_conditions',
    defaultMessage: 'Terms & Conditions',
  });

  const privacyPolicy = intl.formatMessage({
    id: 'footer.privacy_policy',
    defaultMessage: 'Privacy Policy',
  });

  const termsURL = intl.formatMessage(
    {
      id: 'footer.terms_url',
      defaultMessage: `https://www.quipper.com/${userCountryCode}/terms/`,
    },
    {
      country: userCountryCode,
    },
  );

  const privacyPolicyURL = intl.formatMessage(
    {
      id: 'footer.privacy_policy_url',
      defaultMessage: `https://www.quipper.com/${userCountryCode}/privacy/`,
    },
    {
      country: userCountryCode,
    },
  );

  const contactUs = intl.formatMessage({
    id: 'footer.contact_us',
    defaultMessage: 'Contact Us',
  });

  return (
    <>
      <BaseFooter className="Footer desktop" isHidden="mobile">
        <Container isCompact>
          <Level>
            <LevelLeft>
              <LevelItem>
                <img src={BrandFooter} className="Brand" />
              </LevelItem>
              <LevelItem>
                <span className="copyright">
                  &copy;{currentYear} Quipper Limited
                </span>
              </LevelItem>
            </LevelLeft>
            <LevelRight>
              <LevelItem>
                <LanguageSelector />
              </LevelItem>
              <LevelItem href={termsURL} target="_blank" data-testid="termsURL">
                {termsAndConditions}
              </LevelItem>
              <LevelItem
                href={privacyPolicyURL}
                target="_blank"
                data-testid="privacyPolicyURL"
              >
                {privacyPolicy}
              </LevelItem>
              <LevelItem
                href="https://www.quipper.com/contacts"
                target="_blank"
              >
                {contactUs}
              </LevelItem>
            </LevelRight>
          </Level>
        </Container>
      </BaseFooter>
      <BaseFooter className="Footer mobile" isHidden="tablet">
        <Container isCompact>
          <div>
            <span className="mobile-item">
              <LanguageSelector />
            </span>
            <span className="mobile-item">
              <a
                href={termsURL}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                {termsAndConditions}
              </a>
            </span>
          </div>
          <div>
            <span className="mobile-item">
              <a
                href={privacyPolicyURL}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                {privacyPolicy}
              </a>
            </span>
            <span className="mobile-item">
              <a
                href="https://www.quipper.com/contacts"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
              >
                {contactUs}
              </a>
            </span>
          </div>
          <div className="brand-container">
            <span className="mobile-item">
              <img src={BrandFooter} className="Brand" />
            </span>
            <span className="mobile-item">
              <span className="copyright">
                &copy;{currentYear} Quipper Ltd.
              </span>
            </span>
          </div>
        </Container>
      </BaseFooter>
    </>
  );
};

export default injectIntl(Footer);
