import * as React from 'react';
import classNames from 'classnames';

import Icon from '../Icon';
import { colorTypes } from '../../types';

import './Input.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color?: colorTypes;
}

const Input = ({
  color,
  className,
  type,
  ...rest
}: InputProps): React.ReactElement => {
  const [passwordState, setPasswordState] = React.useState({
    type: 'password',
    icon: 'invisible',
  });

  const togglePasswordState = (): void => {
    const passwordIcon =
      passwordState.type == 'password' ? 'visible' : 'invisible';
    const passwordType = passwordState.type == 'password' ? 'text' : 'password';

    setPasswordState({
      type: passwordType,
      icon: passwordIcon,
    });
  };

  const inputClassName = classNames([
    'Input',
    { [`Input--disabled`]: !color },
    { [`Input--${color}`]: color },
    { [`${className}`]: className },
  ]);

  if (type == 'password') {
    return (
      <span className="PasswordInput">
        <input className={inputClassName} type={passwordState.type} {...rest} />
        <span
          className="PasswordInput__Icon"
          onClick={togglePasswordState}
          onKeyDown={(e) => {
            if (e.key === 'Enter') togglePasswordState();
          }}
        >
          <Icon icon={passwordState.icon} />
        </span>
      </span>
    );
  }

  return <input className={inputClassName} type={type} {...rest} />;
};

Input.defaultProps = { color: '' };

export default Input;
