import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import { navigate } from '@reach/router';
import { Control, Field, Select, Label, NavbarItem } from 'bloomer';

import './CountrySelector.scss';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  options: object[];
}

const CountrySelector: React.FC<SelectProps & WrappedComponentProps> = ({
  intl,
  options,
  defaultValue,
  ...rest
}: SelectProps & WrappedComponentProps): React.ReactElement => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    navigate(`/signup/${e.target.value}`);
  };

  return (
    <>
      <NavbarItem isHidden="mobile">
        <Field className="country-selector-label">
          <Label htmlFor="country">
            {intl.formatMessage({
              id: 'header.country',
              defaultMessage: 'Country',
            })}
          </Label>
        </Field>
      </NavbarItem>
      <NavbarItem>
        <Field className="country-selector-field">
          <Control>
            <Select
              {...rest}
              onChange={handleChange}
              defaultValue={defaultValue}
            >
              {options.map(
                (option: string[], index: number): React.ReactElement => (
                  <option key={index} value={option['value']}>
                    {intl.formatMessage({
                      id: `header.${option['slug']}`,
                      defaultMessage: 'Country',
                    })}
                  </option>
                ),
              )}
            </Select>
          </Control>
        </Field>
      </NavbarItem>
    </>
  );
};

export default injectIntl(CountrySelector);
