import * as React from 'react';
import { Help } from 'bloomer';
import './ValidationList.scss';

interface Props {
  title: string;
  children: React.ReactNode;
}

const ValidationList = ({ title, children }: Props): React.ReactElement => {
  return (
    <div className="validation-list">
      <Help className="validation-list__title">{title}</Help>
      <div className="validation-list__items">{children}</div>
    </div>
  );
};

export default ValidationList;
