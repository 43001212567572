import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { useState, useEffect } from 'react';
import Overlay from '../atoms/OverlayBackground';
import Button from '../atoms/Button';
import { Config } from '../../config';
import {
  getErrorBody,
  getErrorMessage,
  getErrorPath,
} from '../../utils/errorUtils';
import { cookies } from '../lib/Storage';
import { navigate } from '@reach/router';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Columns, Column } from 'bloomer';
import './ErrorDialog.scss';

interface Props {
  active?: boolean;
  error?: object | undefined;
  errorString?: string;
  countryCode?: string;
}

const errorDefaultProps = {
  active: true,
};

export const errorProperties = {
  sessionExpired: {
    titleIntl: 'error_dialog.expired_session.title',
    contentIntl: 'error_dialog.expired_session.content',
    title: 'Please login/sign-up to continue.',
    content: '',
    reload: false,
    login: true,
    signup: true,
    close: false,
  },
  orderFailed: {
    titleIntl: 'error_dialog.order_failed.title',
    contentIntl: 'error_dialog.order_failed.content',
    title: "We're sorry, we are unable to process this order right now.",
    content:
      'Please try again or try a different payment method.<br/>If the problem persists, please contact Customer Support.',
    reload: false,
    login: false,
    signup: false,
    close: true,
  },
  orderLimitReached: {
    titleIntl: 'error_dialog.order_limit_reached.title',
    contentIntl: 'error_dialog.order_limit_reached.content',
    title: "We're sorry, we are unable to process this order right now.",
    content:
      "You've reached daily order limit created in a day, please try again tomorrow.<br/>For further information, please contact Customer Support.",
    reload: false,
    login: false,
    signup: false,
    close: true,
  },
  unableToLoadPage: {
    titleIntl: 'error_dialog.unable_to_load_page.title',
    contentIntl: 'error_dialog.unable_to_load_page.content',
    title: "We're sorry, we are unable to load this page.",
    content: 'Please reload the page.',
    reload: true,
    login: false,
    signup: false,
    close: true,
  },
  noDataFound: {
    titleIntl: 'error_dialog.no_data_found.title',
    contentIntl: 'error_dialog.no_data_found.content',
    title: 'No data found!',
    content: 'We cannot find any of your data.',
    reload: true,
    login: false,
    signup: false,
    close: true,
  },
  unprocessable: {
    titleIntl: 'error_dialog.unprocessable.title',
    contentIntl: 'error_dialog.unprocessable.content',
    title: "We're sorry, an error has occurred.",
    content: 'Please reload the page.',
    reload: true,
    login: false,
    signup: false,
    close: true,
  },
  verificationNotFound: {
    titleIntl: 'error_dialog.expired_session.title',
    contentIntl: 'error_dialog.no_data_found.content',
    title: 'Please login/sign-up to continue.',
    content: 'We cannot find any of your data.',
    reload: false,
    login: true,
    signup: true,
    close: false,
  },
};

const reloadPage = (): void => {
  location.reload();
};

const goToLogin = (): void => {
  const language = cookies.get('language') || 'en';
  let learnUrl = Config.learnUrl + '/' + language + '/login';
  const previousUrl = cookies.get('previousUrl');
  if (previousUrl) {
    learnUrl = learnUrl + '?return_url=' + previousUrl;
  }
  navigate(learnUrl);
};

const goToSignUp = (): void => {
  navigate('/');
};

const isClientError = (error: object | undefined): boolean => {
  return (
    getErrorMessage(error) == 'Bad Request' ||
    getErrorMessage(error) == 'Unprocessable Entity'
  );
};

const isAuthError = (error: object | undefined): boolean => {
  const errorBody = getErrorBody(error);
  if (isNil(errorBody) || !isString(errorBody)) {
    return false;
  }

  return (
    errorBody.includes("Can't find token") ||
    errorBody.includes('auth type is not set or incorrect')
  );
};

const isOrderError = (error: object | undefined): boolean => {
  const errorPath = getErrorPath(error);
  return isString(errorPath) && errorPath == 'createOrder';
};

const isErrorPersist = (error: object | undefined): boolean => {
  const errorBody = getErrorBody(error);
  return isString(errorBody);
};

const getErrorType = (error: object | undefined): string => {
  if (isAuthError(error)) {
    cookies.set('previousUrl', window.location.href);
    return 'sessionExpired';
  } else if (isOrderError(error)) {
    return 'orderFailed';
  } else if (isErrorPersist(error)) {
    return 'unableToLoadPage';
  } else {
    return 'unprocessable';
  }
};

const ErrorDialog: React.FC<Props & WrappedComponentProps> = ({
  active,
  error,
  errorString,
  countryCode,
  intl,
}: Props & WrappedComponentProps): React.ReactElement => {
  const [isActive, setActive] = useState(active);
  const region = countryCode || 'id';
  const cSPhoneNumber = intl.formatMessage({
    id: `error_dialog.${region.toLowerCase()}_customer_service_phone_number`,
  });

  const dismiss = (): void => {
    setActive(false);
  };

  useEffect((): void => {
    setActive(active);
  }, [active]);

  if (isClientError(error)) {
    Sentry.captureException(error);
  }

  const errorType = errorString || getErrorType(error);

  return (
    <Overlay active={isActive}>
      <section className="ErrorDialog">
        <h3 className="ErrorDialog__title">
          {intl.formatMessage({
            id: errorProperties[errorType].titleIntl,
            defaultMessage: errorProperties[errorType].title,
          })}
        </h3>

        {errorType != 'sessionExpired' && (
          <article className="ErrorDialog__content">
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  {
                    id: errorProperties[errorType].contentIntl,
                    defaultMessage: errorProperties[errorType].content,
                  },
                  { customerServicePhone: cSPhoneNumber },
                ),
              }}
            />
          </article>
        )}

        <article className="ErrorDialog__footer">
          <Columns>
            {errorProperties[errorType].close && (
              <Column>
                <Button color="info" isText onClick={dismiss}>
                  {intl.formatMessage({
                    id: 'error_dialog.close',
                    defaultMessage: 'Close',
                  })}
                </Button>
              </Column>
            )}

            {errorProperties[errorType].reload && (
              <Column>
                <Button color="info" isText onClick={reloadPage}>
                  {intl.formatMessage({
                    id: 'error_dialog.reload',
                    defaultMessage: 'Reload',
                  })}
                </Button>
              </Column>
            )}

            {errorProperties[errorType].login && (
              <Column>
                <Button color="info" isText onClick={goToLogin}>
                  {intl.formatMessage({
                    id: 'error_dialog.login',
                    defaultMessage: 'Log In',
                  })}
                </Button>
              </Column>
            )}
            {errorProperties[errorType].signup && (
              <Column>
                <Button color="info" isText onClick={goToSignUp}>
                  {intl.formatMessage({
                    id: 'error_dialog.sign_up',
                    defaultMessage: 'Sign Up',
                  })}
                </Button>
              </Column>
            )}
          </Columns>
        </article>
      </section>
    </Overlay>
  );
};

ErrorDialog.defaultProps = errorDefaultProps;

export default injectIntl(ErrorDialog);
