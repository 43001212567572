import * as React from 'react';
import {
  Modal,
  ModalBackground,
  ModalCard,
  ModalCardHeader,
  ModalCardBody,
  ModalCardFooter,
} from 'bloomer';
import { FormattedMessage } from 'react-intl';
import Button from '../../atoms/Button';
import './RegistrationFormRegistered.scss';
import { Config } from '../../../config';

interface Props {
  isActive: boolean;
  onClose: (show: boolean) => void;
}

const RegistrationFormRegistered = ({
  isActive,
  onClose,
}: Props): React.ReactElement => {
  const goToLearn = (): void => {
    window.location.assign(Config.learnUrl);
  };

  return (
    <Modal isActive={isActive} className={'RegistrationForm__registered'}>
      <ModalBackground />
      <ModalCard>
        <ModalCardHeader>
          <h3 className="RegistrationForm__registered__title">
            <FormattedMessage
              id="registration.registered.title"
              defaultMessage="Account has been registered"
            />
          </h3>
        </ModalCardHeader>
        <ModalCardBody>
          <p className="RegistrationForm__registered__description">
            <FormattedMessage
              id="registration.registered.description"
              defaultMessage="The email from this social account is already registered. Please continue to Login."
            />
          </p>
        </ModalCardBody>
        <ModalCardFooter>
          <Button color="light" onClick={(): void => onClose(false)}>
            <FormattedMessage
              id="registration.registered.cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button color="info" onClick={goToLearn}>
            <FormattedMessage
              id="registration.registered.continue"
              defaultMessage="Continue to Login"
            />
          </Button>
        </ModalCardFooter>
      </ModalCard>
    </Modal>
  );
};

export default RegistrationFormRegistered;
