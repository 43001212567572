import * as React from 'react';
import classNames from 'classnames';

import Back from '../../assets/icons/back.svg';
import BackWhite from '../../assets/icons/back-white.svg';
import BackDark from '../../assets/icons/back-dark.svg';
import Plan from '../../assets/icons/plan.svg';
import Shop from '../../assets/icons/shop.svg';
import ActivationCard from '../../assets/icons/activation-card.svg';
import Next from '../../assets/icons/next.svg';
import Success from '../../assets/icons/success.svg';
import Danger from '../../assets/icons/danger.svg';
import Time from '../../assets/icons/time.svg';
import Account from '../../assets/icons/account.svg';
import Close from '../../assets/icons/close.svg';
import Visible from '../../assets/icons/visible.svg';
import Invisible from '../../assets/icons/invisible.svg';
import Info from '../../assets/icons/info.svg';
import Card from '../../assets/icons/card.svg';
import Dropup from '../../assets/icons/dropup.svg';
import Dropdown from '../../assets/icons/dropdown.svg';
import DropupDark from '../../assets/icons/dropup-dark.svg';
import DropdownDark from '../../assets/icons/dropdown-dark.svg';
import Tick from '../../assets/icons/tick.svg';
import Check from '../../assets/icons/check.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Forbidden from '../../assets/icons/forbidden.svg';
import Google from '../../assets/icons/google.svg';
import HollowTick from '../../assets/icons/hollow-tick.svg';
import CloseRed from '../../assets/icons/close-red.svg';
import CloseGrey from '../../assets/icons/close-grey.svg';
import Warning from '../../assets/icons/warning.svg';
import Discount from '../../assets/icons/discount.svg';
import Checklist from '../../assets/icons/checklist.svg';
import DiscountOutline from '../../assets/icons/discount-outline.svg';

import './Icon.scss';

const getIcon = {
  back: Back,
  backWhite: BackWhite,
  backDark: BackDark,
  plan: Plan,
  shop: Shop,
  activationCard: ActivationCard,
  next: Next,
  success: Success,
  danger: Danger,
  time: Time,
  account: Account,
  close: Close,
  closeRed: CloseRed,
  closeGrey: CloseGrey,
  visible: Visible,
  invisible: Invisible,
  info: Info,
  card: Card,
  dropup: Dropup,
  dropdown: Dropdown,
  dropupDark: DropupDark,
  dropdownDark: DropdownDark,
  tick: Tick,
  check: Check,
  facebook: Facebook,
  forbidden: Forbidden,
  google: Google,
  hollowTick: HollowTick,
  warning: Warning,
  discount: Discount,
  checklist: Checklist,
  discountOutline: DiscountOutline,
};

interface Props {
  icon: string;
  align?: 'left' | 'right';
  size?: 'sm' | 'md' | 'lg';
}

const Icon = ({ align, icon, size = 'md' }: Props): React.ReactElement => {
  const iconClassName = classNames([
    'Icon',
    { [`Icon--${align}`]: align },
    { [`Icon--${size}`]: size },
  ]);

  return <img className={iconClassName} src={getIcon[icon]} />;
};

export default Icon;
