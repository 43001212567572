import * as S from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import Config from './config';

export default class Sentry {
  public static init(): void {
    S.init({
      dsn: Config.SentryDSN,
      environment: Config.SentryEnvironment,
      integrations: [new BrowserTracing()],
      ignoreErrors: [
        /Cannot read property 'getReadModeExtract' of undefined/i,
        /Cannot read property 'getReadModeRender' of undefined/i,
        /Cannot read property 'getReadModeConfig' of undefined/i,
      ],
    });
  }
}
