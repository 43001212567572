import * as React from 'react';
import Brand from '../../atoms/Brand';
import Container from '../../molecules/Container';
import {
  Navbar,
  NavbarBrand,
  NavbarMenu,
  NavbarEnd,
  NavbarItem,
} from 'bloomer';

import './Header.scss';

interface HeaderProps {
  children?: React.ReactNode;
}

const Header = ({ children }: HeaderProps): React.ReactElement => (
  <Navbar className="Navbar">
    <Container isCompact>
      <NavbarBrand>
        <NavbarItem>
          <Brand />
        </NavbarItem>
      </NavbarBrand>
      <NavbarMenu>
        <NavbarEnd>{children}</NavbarEnd>
      </NavbarMenu>
    </Container>
  </Navbar>
);

export default Header;
