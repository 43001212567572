import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { navigate, RouteComponentProps } from '@reach/router';
import BaseTemplate from '../templates/BaseTemplate';
import Footer from '../molecules/Footer';
import Header from '../molecules/header';
import Button from '../atoms/Button';
import NotFoundImg from '../../assets/images/404.svg';

import './NotFoundPage.scss';

const NotFoundPage: React.FC<RouteComponentProps & WrappedComponentProps> = ({
  intl,
}: RouteComponentProps & WrappedComponentProps): React.ReactElement => {
  document.title = intl.formatMessage({
    id: 'page_title.other',
    defaultMessage: 'Quipper',
  });

  const renderBody = (
    <section className="NotFoundPage__body">
      <div className="NotFoundContent">
        <div className="NotFoundContent__Image">
          <img src={NotFoundImg} />
        </div>
        <div className="NotFoundContent__Message">
          <span>
            {intl.formatMessage({
              id: 'not_found.message',
            })}
          </span>
        </div>
        <h1 className="NotFoundContent__Heading">
          {intl.formatMessage({
            id: 'not_found.title',
          })}
        </h1>

        <div className="NotFoundContent__Description">
          <p>
            {intl.formatMessage({
              id: 'not_found.description',
            })}
          </p>
        </div>
        <div className="NotFoundContent__ButtonContainer">
          <Button
            onClick={(): Promise<void> => navigate('/')}
            id="NotFoundContent__Button"
          >
            {intl.formatMessage({
              id: 'not_found.button',
            })}
          </Button>
        </div>
      </div>
    </section>
  );

  return (
    <BaseTemplate
      className="NotFoundPage"
      header={<Header />}
      body={renderBody}
      footer={<Footer />}
    />
  );
};

export default injectIntl(NotFoundPage);
