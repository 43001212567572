import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloClient } from 'apollo-boost';
import { cookies } from '../lib/Storage';
import { Config } from '../../config';
import fetch from 'cross-fetch';

type TokenMethod = 'Basic' | 'Token';

export const httpLink = createHttpLink({
  uri: '/api/graphql',
  fetch: fetch,
});

interface AuthHeader {
  Authorization?: string;
}

export const authHeadersFactory = (
  tokenMethod: TokenMethod,
  token: string | undefined,
): AuthHeader => {
  const obj: AuthHeader = {};

  if (!!token) {
    obj.Authorization = `${tokenMethod} ${token}`;
  }

  return obj;
};

const authLink = setContext((_, { headers }): { headers: object } => {
  // Enfore specified pathnames to use client authentication
  const clientAuth: boolean = Config.clientAuthenticationPathnames.includes(
    window.location.pathname,
  );
  // get the authentication token from local storage if it exists
  const token: string | undefined = cookies.get(Config.authTokenCookieName);
  const basicToken: string | undefined = Config.basicToken;

  const getAuthHeaders = (): AuthHeader => {
    switch (true) {
      case clientAuth:
        return authHeadersFactory('Basic', basicToken);
      case !!token:
        return authHeadersFactory('Token', token);
      case !!basicToken:
        return authHeadersFactory('Basic', basicToken);
      default:
        return {};
    }
  };

  return {
    headers: {
      ...headers,
      ...getAuthHeaders(),
    },
  };
});

const ApiClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default ApiClient;
