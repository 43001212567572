import * as React from 'react';
import Root from './Root';
import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import I18n from '../utils/I18n';
import ApiClient from './apollo/ApiClient';

const App = (): React.ReactElement => {
  return (
    <div className="App">
      <IntlProvider
        locale={I18n.currentLocale()}
        messages={I18n.message()}
        textComponent="span"
      >
        <ApolloProvider client={ApiClient}>
          <Root />
        </ApolloProvider>
      </IntlProvider>
    </div>
  );
};

export default App;
