import gql from 'graphql-tag';

export const GET_REGISTRATION_DATA = gql`
  query registrationData($data: String!) {
    registrationData(data: $data) {
      firstName
      lastName
      email
      gradeLevel
      gender
      phoneNumber
      countryCode
      organizationMembershipCode
    }
  }
`;
