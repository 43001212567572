import * as React from 'react';
import classNames from 'classnames';

import './OverlayBackground.scss';

interface Props {
  active?: boolean;
  children: React.ReactNode;
}

const OverlayBackground = ({ active, children }: Props): React.ReactElement => {
  const overlayClassName = classNames([
    'Overlay',
    {
      'Overlay--show': active,
    },
  ]);

  return <div className={overlayClassName}>{children}</div>;
};

export default OverlayBackground;
