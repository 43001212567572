import React from 'react';
import VerficationSuccessImg from '../../../assets/images/email-verification-success.png';
import { Button } from 'bloomer';
import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from 'react-intl';
import Countdown from '../../atoms/Countdown';
import { clearCountdown } from '../../lib/Helpers';

import './VerificationSuccess.scss';

interface Props {
  redirectUrl: string;
}

const VerificationSuccess = ({
  redirectUrl,
}: WrappedComponentProps & Props): React.ReactElement => {
  const dateRedirect = new Date();
  dateRedirect.setSeconds(dateRedirect.getSeconds() + 5);

  return (
    <div className="VerificationSuccess__wrapper">
      <img src={VerficationSuccessImg} className="VerificationSuccess__img" />

      <h1 className="VerificationSuccess__title">
        <FormattedMessage
          id="verification.success.title"
          defaultMessage="Verification Success!"
        />
      </h1>
      <p className="VerificationSuccess__description">
        <FormattedMessage
          id="verification.success.description"
          defaultMessage="Your email has been successfully verified. Please continue to start
          learning."
        />
      </p>
      <p className="VerificationSuccess__redirect">
        <FormattedMessage
          id="verification.success.redirect"
          defaultMessage="You will be redirected in"
        />
        <br />
        <Countdown
          date={dateRedirect}
          ticking={true}
          onCompleted={() => window.location.assign(redirectUrl)}
        />
      </p>
      <a
        href={redirectUrl}
        onClick={() => clearCountdown()}
        className="VerificationSuccess__link"
      >
        <Button isColor="info">
          <FormattedMessage
            id="verification.success.start_learning"
            defaultMessage="Start Learning"
          />
        </Button>
      </a>
    </div>
  );
};

export default injectIntl(VerificationSuccess);
