// Match http://, https:// or javascript:
const external = /^(https?:\/\/.+|javascript:.+)/i;

export const isInternalLink = (link: string): boolean => !external.test(link);

export const getBaseUrl = (link: string): string | null => {
  if (link) {
    try {
      const url = new URL(link);
      const baseUrl = `${url.protocol}//${url.hostname}`;

      return baseUrl;
    } catch {
      return null;
    }
  }

  return null;
};
