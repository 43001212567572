import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
} from 'date-fns';

import Icon from '../atoms/Icon';
import { clearCountdown } from '../lib/Helpers';
import './Countdown.scss';

interface CountdownProps extends WrappedComponentProps {
  date: Date;
  order?: boolean;
  ticking?: boolean;
  onCompleted?: () => void;
}

interface CountdownInterface {
  day: number;
  hour: number;
  minute: number;
  second: number;
}

const countdown = {
  day: 0,
  hour: 0,
  minute: 0,
  second: 0,
};

const Countdown: React.FC<CountdownProps> = ({
  date,
  order,
  ticking,
  onCompleted,
  intl,
}: CountdownProps): React.ReactElement => {
  const [timer, setTimer] = React.useState<CountdownInterface>(countdown);

  React.useEffect((): void => {
    if (!ticking) {
      clearCountdown();
    }

    const timeNow = new Date();
    const currentCountdown = localStorage.getItem('countdownCounter');

    if (date >= timeNow && !currentCountdown) {
      setTimer({
        day: differenceInDays(date, timeNow),
        hour: differenceInHours(date, timeNow) % 24,
        minute: differenceInMinutes(date, timeNow) % 60,
        second: differenceInSeconds(date, timeNow) % 60,
      });
    }

    if (currentCountdown) {
      setTimer({ ...JSON.parse(currentCountdown) });
    }
  }, []);

  const t = (id: string, defaultMessage: string): string =>
    intl.formatMessage({ id: id, defaultMessage: defaultMessage });

  const setTime = (): string => {
    const tDays = t('payment.invoice.times.days', 'Days');
    const tHours = t('payment.invoice.times.hours', 'Hours');
    const tMinutes = t('payment.invoice.times.minutes', 'Minutes');

    const setDays =
      `${timer.day} ${tDays}` +
      (timer.hour > 0 ? ` ${timer.hour} ${tHours}` : '');
    const setHours =
      `${timer.hour} ${tHours}` +
      (timer.minute > 0 ? ` ${timer.minute} ${tMinutes}` : '');
    const setMinutes = `${timer.minute} ${tMinutes}`;

    if (ticking) {
      return `${timer.minute < 10 ? '0' + timer.minute : timer.minute} : ${
        timer.second < 10 ? '0' + timer.second : timer.second
      }`;
    } else {
      return timer.day > 0 ? setDays : timer.hour > 0 ? setHours : setMinutes;
    }
  };

  React.useEffect(() => {
    if (ticking) {
      const countdownInterval = setInterval(() => {
        if (timer.second > 0) {
          localStorage.setItem('countdownCounter', JSON.stringify(timer));

          setTimer({
            ...timer,
            second: timer.second - 1,
          });
        }

        if (timer.second === 0) {
          if (timer.minute === 0) {
            clearCountdown();
            clearInterval(countdownInterval);
            onCompleted && onCompleted();
          } else {
            setTimer({
              ...timer,
              minute: timer.minute - 1,
              second: 59,
            });
          }
        }
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
    return;
  }, [timer]);

  if (ticking) {
    return <span className="Countdown">{setTime()}</span>;
  }

  if (!order) {
    return <div className="Countdown">{setTime()}</div>;
  } else {
    return (
      <div className="Countdown">
        <Icon icon={'time'} />
        &nbsp;&nbsp;{setTime()}
      </div>
    );
  }
};

export default injectIntl(Countdown);
