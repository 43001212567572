import gql from 'graphql-tag';

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $password: String!
    $phoneNumber: String!
    $gradeLevel: String!
    $countryCode: String
    $facebookToken: String
    $googleToken: String
    $organizationMembershipCode: String
    $gender: String
    $agreedToAgeConsent: Boolean
    $agreedToTerms: Boolean
    $registeredFrom: String
  ) {
    createUser(
      user: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        username: $username
        password: $password
        phoneNumber: $phoneNumber
        gradeLevel: $gradeLevel
        countryCode: $countryCode
        facebookToken: $facebookToken
        googleToken: $googleToken
        organizationMembershipCode: $organizationMembershipCode
        gender: $gender
        agreedToAgeConsent: $agreedToAgeConsent
        agreedToTerms: $agreedToTerms
        registeredFrom: $registeredFrom
      }
    ) {
      id
      accessToken
      name
      email
      username
      phoneNumber
      gradeLevel
      countryCode
    }
  }
`;
