import remove from 'lodash/remove';

interface MaintenanceConfig {
  registrationPage: string[];
  paymentPage: string[];
  infoPage: string[];
}
/**
 * Type for our static configuration
 * This is expected to be immutable but it's not for now.
 */
interface ConfigType {
  orderLimitPerDay: number;
  meetOrderLimitPerDay(): boolean;
  reCaptchaSiteKey: string;
  SentryDSN: string;
  SentryEnvironment: string;
  authTokenCookieName: string;
  basicToken: string;
  isProductionEnv: boolean;
  isTestEnv: boolean;
  facebookAppId: string;
  googleClientId: string;
  googlePlayUrl: string;
  learnUrl: string;
  gtmId: string;
  cookieDomain: string;
  conektaPublicKey: string;
  maintenance: MaintenanceConfig;
  hotjarId: string;
  hotjarDebug: boolean;
  veritransApiHost: string;
  veritransClientKey: string;
  veritransServerKey: string;
  clientAuthenticationPathnames: string[];
  moengageAppId: string;
  moengageEnvironment: number;
  dummyPassword: string;
  pricingPlansVariantExperimentId: string;
  firebaseAppId: string;
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  firebaseDatabaseUrl: string;
  firebaseProjectId: string;
  firebaseStorageBucket: string;
  firebaseMessagingSenderId: string;
  firebaseMeasurementId: string;
  firebaseRemoteConfigFetchInterval: number;
  collaborateUrl: string;
}

/**
 * StoredData abstracting local storage for application specific
 * needs.
 * This type encapsulate data access and provide methods which directly
 * translate to data type required by each business cases.
 */
interface StoredData {
  currentSubmitCount(): number;
  incrCurrentSubmitCount(): void;
  resetLocalStorage(): void;
  getData(name: string): string;
  setData(listData: string[][]): void;
  removeData(listData: string[]): void;
}

const Store: StoredData = ((ls): StoredData => {
  const today = new Date();
  const lsName = `${today.getMonth()}${today.getDate()}-orderSubmissionCount`;

  return {
    /**
     * Returns current order submissions count from storage
     *
     * @returns order submissions count
     */
    currentSubmitCount: (): number => parseInt(ls.getItem(`${lsName}`) || '0'),

    /**
     * Increase current submissions count, supposed to be called after each success order
     */
    incrCurrentSubmitCount: (): void => {
      const cnt: number = parseInt(ls.getItem(`${lsName}`) || '0');
      ls.setItem(`${lsName}`, (cnt + 1).toString());
    },

    /**
     * Reset localStorage data
     */
    resetLocalStorage: (): void => {
      const listData: string[] = [
        'paymentAgent',
        'paymentMethod',
        'paymentProvider',
        'pricingPlanId',
        'pricingPlanName',
        'pricingPlanPrice',
        'pricingPlanPriceNumeric',
        'pricingPlanCategory',
        'discount',
        'promotionCode',
        'directPurchase',
        'userId',
        'customerName',
        'customerEmail',
        'customerMobileNumber',
        'encCardData',
      ];
      listData.forEach((data): void => localStorage.removeItem(data));
    },

    /**
     * Get local storage data
     */
    getData: (name: string): string => {
      const result = localStorage.getItem(name);
      return result || '';
    },

    /**
     * Set local storage data
     */
    setData: (listData: string[][]): void => {
      listData.forEach((data): void => localStorage.setItem(data[0], data[1]));
    },

    /**
     * Reset local storage data
     */
    removeData: (listData: string[]): void => {
      listData.forEach((data): void => localStorage.removeItem(data));
    },
  };
})(localStorage);

const Config: ConfigType = ((env): ConfigType => {
  return {
    /**
     * order limit per day before we starts showing recaptcha
     */
    orderLimitPerDay: parseInt(env.VIDEO_PAYMENT_ORDER_LIMIT_PER_DAY || '0'),

    /**
     * sitekey for google's recaptcha
     */
    reCaptchaSiteKey: env.VIDEO_PAYMENT_RECAPTCHA_SITE_KEY || '',

    /**
     * Public key for Conekta
     */
    conektaPublicKey: env.VIDEO_PAYMENT_CONEKTA_PUBLIC_KEY || '',

    /**
     * Check whether current submissions count for order is
     * already meet per-day limit.
     */
    meetOrderLimitPerDay: (): boolean => {
      return (
        Store.currentSubmitCount() > 0 &&
        Config.orderLimitPerDay > 0 &&
        Store.currentSubmitCount() >= Config.orderLimitPerDay &&
        env.VIDEO_PAYMENT_SHOW_CAPTCHA === 'true'
      );
    },

    /**
     * DSN for sentry
     */
    SentryDSN: env.VIDEO_PAYMENT_SENTRY_DSN || '',
    SentryEnvironment: env.VIDEO_PAYMENT_SENTRY_ENVIRONMENT || 'localhost',

    /**
     * Cookie name for auth token
     */
    authTokenCookieName:
      env.VIDEO_PAYMENT_AUTH_TOKEN_COOKIE_NAME || 'learn_auth_token',

    /**
     * Token for basic auth
     */
    basicToken: env.VIDEO_PAYMENT_BASIC_TOKEN || '',

    /**
     * reflect running environment, true if we run in production mode
     */
    isProductionEnv: env.NODE_ENV === 'production',

    /**
     * reflect running environment, true if we run in test mode
     */
    isTestEnv: env.NODE_ENV === 'test',

    /**
     * Facebook App Id
     */
    facebookAppId: env.VIDEO_PAYMENT_FACEBOOK_APP_ID || '',

    /**
     * Google Client Id
     */
    googleClientId: env.VIDEO_PAYMENT_GOOGLE_CLIENT_ID || '',

    googlePlayUrl:
      env.GOOGLE_PLAY_URL ||
      'https://play.google.com/store/apps/details?id=com.quipper.school.assignment',

    /**
     * Learn url
     */
    learnUrl: env.VIDEO_PAYMENT_LEARN_QUIPPER_URL || '',

    /**
     * Collaborate url
     */
    collaborateUrl:
      env.VIDEO_PAYMENT_COLLABORATE_URL || 'https://collaborate.quipper.net',

    /**
     * GTM ID
     */
    gtmId: env.VIDEO_PAYMENT_GOOGLE_TAG_MANAGER_ID || '',

    /**
     * domain for cookies
     * we need to set the domain to empty string for other envs beside production
     * otherwise it will always be overwritten by other env specified in .env files
     */
    cookieDomain:
      env.NODE_ENV === 'production'
        ? env.VIDEO_PAYMENT_COOKIE_DOMAIN || ''
        : '',

    /**
     * Maintenance config for each features per country
     */
    maintenance: ((): MaintenanceConfig => {
      const rmEmpty = (arr: string[]): string[] => {
        remove(arr, (s: string): boolean => s.length === 0);
        return arr;
      };

      return {
        registrationPage: rmEmpty(
          (env.VIDEO_PAYMENT_MAINTENANCE_REGISTRATION_PAGE || '').split(','),
        ),
        paymentPage: rmEmpty(
          (env.VIDEO_PAYMENT_MAINTENANCE_PAYMENT_PAGE || '').split(','),
        ),
        infoPage: rmEmpty(
          (env.VIDEO_PAYMENT_MAINTENANCE_INFO_PAGE || '').split(','),
        ),
      };
    })(),
    /**
     * Hotjar configuration
     */
    hotjarId: env.HOTJAR_ID || '',
    hotjarDebug: env.HOTJAR_DEBUG === 'true',

    /**
     * Veritrans config url
     */
    veritransApiHost: env.VIDEO_PAYMENT_VERITRANS_API_HOST || '',

    /**
     * Veritrans client key
     */
    veritransClientKey: env.VIDEO_PAYMENT_VERITRANS_CLIENT_KEY || '',

    /**
     * Veritrans server key
     */
    veritransServerKey: env.VIDEO_PAYMENT_VERITRANS_SERVER_KEY || '',

    /**
     * Pathnames for Client Authentication
     */
    clientAuthenticationPathnames: ['/signup/ph', '/signup/id'],

    /**
     * Moengage configuration
     * environment = 1 (staging)
     * environment = 0 (production)
     */
    moengageAppId: env.VIDEO_PAYMENT_MOENGAGE_APP_ID || '',
    moengageEnvironment: parseInt(env.VIDEO_PAYMENT_MOENGAGE_ENV || '1'),

    /**
     * dummy password for sonarcloud security
     */
    dummyPassword: 'quipper123',

    /**
     * Google Optimize Pricing Plans Variant Experiment Id
     */
    pricingPlansVariantExperimentId:
      env.VIDEO_PAYMENT_GOOGLE_OPTIMIZE_VARIANT_EXPERIMENT_ID || '',

    /**
     * Firebase project
     */
    firebaseApiKey: env.VIDEO_PAYMENT_FIREBASE_API_KEY || '',
    firebaseAppId:
      env.VIDEO_PAYMENT_FIREBASE_APP_ID ||
      '1:770531585344:web:95249e36741870a1771ea5',
    firebaseAuthDomain:
      env.VIDEO_PAYMENT_FIREBASE_AUTH_DOMAIN || 'qs-dev.firebaseapp.com',
    firebaseDatabaseUrl:
      env.VIDEO_PAYMENT_FIREBASE_DATABASE_URL ||
      'https://qs-dev.firebaseio.com',
    firebaseMeasurementId:
      env.VIDEO_PAYMENT_FIREBASE_MEASUREMENT_ID || 'G-BJL077988V',
    firebaseMessagingSenderId:
      env.VIDEO_PAYMENT_FIREBASE_MESSAGING_SENDER_ID || '770531585344',
    firebaseProjectId: env.VIDEO_PAYMENT_FIREBASE_PROJECT_ID || 'qs-dev',
    firebaseRemoteConfigFetchInterval: parseInt(
      env.VIDEO_PAYMENT_FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL || '43200000',
    ),
    firebaseStorageBucket:
      env.VIDEO_PAYMENT_FIREBASE_STORAGE_BUCKET || 'qs-dev.appspot.com',
  };
})(process.env);

export default Config;

export { Config, Store };
