import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Config } from '../config';

const firebaseConfig = {
  apiKey: Config.firebaseApiKey,
  appId: Config.firebaseAppId,
  authDomain: Config.firebaseAuthDomain,
  databaseURL: Config.firebaseDatabaseUrl,
  messagingSenderId: Config.firebaseMessagingSenderId,
  measurementId: Config.firebaseMeasurementId,
  projectId: Config.firebaseProjectId,
  remoteConfigFetchInterval: Config.firebaseRemoteConfigFetchInterval,
  storageBucket: Config.firebaseStorageBucket,
};

export const enum EventName {
  REGISTRATION_OPEN = 'REGISTRATION_OPEN',
  REGISTRATION_COMPLETED = 'REGISTRATION_COMPLETED',
}

const app = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(app);

export const logFirebaseEvent = (eventName: string): void => {
  logEvent(firebaseAnalytics, eventName);
};

export const trackRegisterUrlFrom = [Config.collaborateUrl];
