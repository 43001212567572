import * as React from 'react';

import BrandImage from '../../assets/images/brand.svg';

import './Brand.scss';

const Brand = (): React.ReactElement => (
  <img className="Brand" src={BrandImage} />
);

export default Brand;
