import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Box, Title, Subtitle } from 'bloomer';

import './ActionLink.scss';

interface Props extends WrappedComponentProps {
  title: string;
  defaultTitle: string;
  subtitle: string;
  defaultSubtitle: string;
  children?: React.ReactNode;
}

const ActionLink: React.FC<Props> = ({
  title,
  defaultTitle,
  subtitle,
  defaultSubtitle,
  children,
  intl,
}: Props & WrappedComponentProps): React.ReactElement => {
  const t = (id: string, defaultMessage: string): string =>
    intl.formatMessage({ id: id, defaultMessage: defaultMessage });

  return (
    <Box className="ActionLink">
      <Title>{t(title, defaultTitle)}</Title>
      <Subtitle>{t(subtitle, defaultSubtitle)}</Subtitle>
      {children}
    </Box>
  );
};

export default injectIntl(ActionLink);
