import * as React from 'react';
import { Help } from 'bloomer';
import { FieldsState } from '../../interfaces/Registration';
import { UserFormFieldsState } from '../../interfaces/UserForm';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import './ErrorMessage.scss';

type Fields = FieldsState | UserFormFieldsState;

// Multiple fields in fieldName can be passed using ", " as separator.
// See: firstName and lastName error messages in RegistrationForm.
interface Props extends WrappedComponentProps {
  fieldName: string;
  fields: Fields;
  minLength?: number;
  maxLength?: number;
}

const ErrorMessage: React.FC<Props> = ({
  fieldName,
  fields,
  minLength,
  maxLength,
  intl,
}: Props): React.ReactElement => {
  const generateErrorMessages = (fieldNames: string[]): string[] => {
    const messages = [] as string[];
    fieldNames.forEach((fieldName: string): void => {
      fields[fieldName].messages.forEach((message: string): void => {
        // This is to normalize error response from VPQS.
        if (message == 'has already been taken') {
          message = 'taken';
        }

        if (message == 'Email have reached email confirmation daily limit') {
          message = 'email_limit_reached';
        }

        messages.push(
          intl.formatMessage(
            {
              id: `registration.form.warning_message.${fieldName}.${message}`,
              defaultMessage: `The translation is missing, error message: ${message}.`,
            },
            {
              minLength: minLength,
              maxLength: maxLength,
            },
          ),
        );
      });
    });

    return messages;
  };

  const generateValidationErrorView = (
    fieldNames: string[],
  ): React.ReactNode | null => {
    const messages = generateErrorMessages(fieldNames);

    if (messages.length === 1) {
      return <Help isColor="danger">{messages[0]}</Help>;
    }

    if (messages.length > 1) {
      return (
        <ul className="help is-danger">
          {messages.map(
            (message: string, index: number): React.ReactNode => (
              <li key={index}>{message}</li>
            ),
          )}
        </ul>
      );
    }

    return null;
  };

  const fieldNames = fieldName.split(',').map((s: string): string => s.trim());

  const validationError = generateValidationErrorView(fieldNames) || null;

  return <>{validationError}</>;
};

export default injectIntl(ErrorMessage);
