import * as React from 'react';
import classNames from 'classnames';
import { languages } from '../lib/Helpers';
import { cookies } from '../lib/Storage';
import { Config } from '../../config';
import Icon from '../atoms/Icon';
import {
  Dropdown,
  DropdownTrigger,
  Button,
  DropdownMenu,
  DropdownContent,
  DropdownItem,
} from 'bloomer';

import './LanguageSelector.scss';

const LanguageSelector = (): React.ReactElement => {
  const { useEffect, useState, useRef } = React;

  const [open, setOpen] = useState<boolean>(false);
  const [icon, setIcon] = useState<string>('dropdown');

  // TODO: Move to I18n.tsx
  const currentLanguage = (): string => {
    const language = cookies.get('language');
    const locale = ['en', 'id'].includes(language) ? language : 'en';
    return languages[locale];
  };
  const [language, setLanguage] = useState<string>(currentLanguage);

  const dropdownClassNames = classNames([
    'LanguageSelector',
    'dropdown',
    'is-up & ',
    { 'is-active': open },
  ]);

  const toggleDropdown = (): void => {
    if (open) {
      setIcon('dropdown');
      setOpen(false);
    } else {
      setIcon('dropup');
      setOpen(true);
    }
  };

  const handleSelection = (code: string): void => {
    cookies.set('language', code, { path: '/', domain: Config.cookieDomain });
    setLanguage(languages[code]);
    setIcon('dropdown');
    setOpen(false);
    window.location.reload();
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleDocumentClick = (e: Event): void => {
    !!dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node) &&
      setOpen(false);
    setIcon('dropdown');
  };

  useEffect((): (() => void) => {
    document.addEventListener('mousedown', handleDocumentClick);

    return (): void => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, []);

  const currentLanguages = Object.keys(languages);

  return (
    <div ref={dropdownRef}>
      <Dropdown className={dropdownClassNames}>
        <DropdownTrigger>
          <Button
            isOutlined
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={toggleDropdown}
            id="LanguageSelectorDropdown"
          >
            <span id="current-language">{language}</span>
            <Icon icon={icon} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownContent>
            {currentLanguages.map(
              (code): React.ReactElement => (
                <DropdownItem
                  key={code}
                  id={`language-${code}`}
                  href="javascript:void(0)"
                  onClick={(): void => handleSelection(code)}
                >
                  {languages[code]}
                </DropdownItem>
              ),
            )}
          </DropdownContent>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default LanguageSelector;
