export interface EmbedViewProps {
  embed?: boolean;
}

export interface SubscriptionProps {
  title: string;
  paidAt: string;
  planName: string;
  expirationDatetime: string;
  formattedSubscriptionPlan: string;
}

export interface OrderDetailProps {
  id: string;
}

export interface FieldProps {
  value: string;
  valid: boolean;
  messages: string[];
}

export interface ValidationItemProps {
  title: string;
  description: string;
  valid: boolean;
}

export const defaultFieldProps = {
  value: '',
  valid: false,
  messages: [],
};

interface JobStatusProps {
  status: string;
}

interface PricingPlanProps {
  name: string;
}

export interface OrderProps {
  id: string;
  type: string;
  formattedAmount: string;
  paymentAgentCode: string;
  paymentAgentTransactionRef: string;
  paymentExpiry: string;
  status: string;
  transactionRef: string;
  jobStatus: JobStatusProps;
  pricingPlan: PricingPlanProps;
}
