import React, { useState, MouseEvent, useEffect } from 'react';
import { Field, Button, Control } from 'bloomer';
import { Mutation } from 'react-apollo';

import {
  injectIntl,
  FormattedMessage,
  WrappedComponentProps,
} from 'react-intl';
import { isDanger } from '../../../utils/errorUtils';
import { VerificationStatus } from '../__mocks__/MockVerification';
import Countdown from '../../atoms/Countdown';
import { CREATE_EMAIL_VERIFICATION } from '../../apollo/mutations/Verification';
import { UserDataInterface } from './interfaces/verification';
import ErrorDialog from '../../molecules/ErrorDialog';

import './VerificationForm.scss';

interface Props {
  handleChange: (
    index: number,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (
    index: number,
  ) => (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handlePaste: (e: React.ClipboardEvent) => void;
  handleSubmit: (e: React.SyntheticEvent) => void;
  disableVerifyButton: boolean;
  codeStatus: VerificationStatus;
  isFormSubmitted: boolean;
  isLoading: boolean;
  userData?: UserDataInterface;
}

const VerificationForm = React.forwardRef(
  (
    {
      handleChange,
      handleSubmit,
      handleKeyDown,
      disableVerifyButton,
      handlePaste,
      codeStatus,
      isFormSubmitted,
      isLoading,
      userData,
    }: WrappedComponentProps & Props,
    ref: React.RefObject<HTMLInputElement[]>,
  ): React.ReactElement => {
    const [onResend, setOnResend] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const insertRef = (index: number) => (el: HTMLInputElement) => {
      if (ref && ref.current) {
        ref.current[index] = el;
      }
    };

    let renderValidation;

    switch (codeStatus) {
      case VerificationStatus.Invalid:
        renderValidation = (
          <p className="VerificationForm__errorMessage">
            <FormattedMessage
              id="verification.status.invalid"
              defaultMessage="Wrong Verification Code"
            />
          </p>
        );
        break;
      case VerificationStatus.Expired:
        renderValidation = (
          <p className="VerificationForm__errorMessage">
            <FormattedMessage
              id="verification.status.expired"
              defaultMessage="Verification code has been expired"
            />
          </p>
        );
        break;
    }

    const handleError = () => {
      setIsError(true);
    };

    useEffect(() => {
      const currentCountdown = localStorage.getItem('countdownCounter');

      if (currentCountdown) {
        setOnResend(true);
      }
    }, []);

    const onResendMouseDownHandler = (e: MouseEvent<HTMLButtonElement>) =>
      e.preventDefault();
    const onCountdownCompletedHanlder = () => setOnResend(false);

    return (
      <form className="VerificationForm">
        <h1 className="VerificationForm__title">
          <FormattedMessage
            id="verification.form.title"
            defaultMessage="Verify Email"
          />
        </h1>
        <p className="VerificationForm__description">
          <FormattedMessage
            id="verification.form.description"
            defaultMessage="Please enter the verification code we sent to your email"
          />
          <b>{` ${userData?.email || ''}`}</b>
        </p>
        <div className="VerificationForm__form__inputs">
          {[...Array(6)].map((_, index) => (
            <Field
              key={index}
              className={isDanger(
                (codeStatus === VerificationStatus.Invalid ||
                  codeStatus === VerificationStatus.Expired) &&
                  isFormSubmitted,
              )}
            >
              <Control>
                <input
                  autoFocus={index === 0}
                  type="text"
                  maxLength={1}
                  ref={insertRef(index)}
                  onChange={handleChange(index)}
                  onKeyDown={handleKeyDown(index)}
                  onPaste={handlePaste}
                  autoComplete="off"
                  required
                />
              </Control>
            </Field>
          ))}
        </div>
        {isFormSubmitted && renderValidation}
        <p className="VerificationForm__resend">
          <Mutation
            mutation={CREATE_EMAIL_VERIFICATION}
            onError={(): void => {
              /* empty handler to prevent thrown errors */
            }}
            onCompleted={(): void => {
              setOnResend(true);
            }}
          >
            {(
              createEmailVerification: any,
              { loading, error }: any,
            ): React.ReactElement => {
              if (loading) {
                return (
                  <FormattedMessage
                    id="verification.form.resend.loading"
                    defaultMessage="Processing..."
                  />
                );
              }

              if (error) {
                const errorMessage =
                  (error.graphQLErrors &&
                    error.graphQLErrors[0] &&
                    error.graphQLErrors[0].extensions &&
                    error.graphQLErrors[0].extensions.body &&
                    Array.isArray(error.graphQLErrors[0].extensions.body) &&
                    error.graphQLErrors[0].extensions.body[0] &&
                    error.graphQLErrors[0].extensions.body[0].code) ||
                  '';

                if (
                  errorMessage ===
                  'Email have reached email confirmation daily limit'
                ) {
                  return (
                    <FormattedMessage
                      id="verification.form.resend.expired"
                      defaultMessage="Sorry you already reach the limit of resend verification code. Please try again tomorrow."
                    />
                  );
                } else {
                  handleError();
                }
              }

              if (onResend || localStorage.getItem('countdownCounter')) {
                const countdownExpiry = new Date();
                countdownExpiry.setMinutes(countdownExpiry.getMinutes() + 2);

                return (
                  <>
                    <FormattedMessage
                      id="verification.form.resend.description"
                      defaultMessage="Haven’t received the verification code?"
                    />
                    <span>
                      {' '}
                      <FormattedMessage
                        id="verification.form.resend.waiting"
                        defaultMessage="Resend verification code in"
                      />
                    </span>
                    <br />
                    <Countdown
                      date={countdownExpiry}
                      ticking={true}
                      onCompleted={onCountdownCompletedHanlder}
                    />
                  </>
                );
              }

              return (
                <>
                  <FormattedMessage
                    id="verification.form.resend.description"
                    defaultMessage="Haven’t received the verification code?"
                  />
                  <span
                    className="VerificationForm__resend__link"
                    onClick={() => {
                      createEmailVerification({
                        variables: {
                          ...userData,
                        },
                      });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        createEmailVerification({
                          variables: {
                            ...userData,
                          },
                        });
                      }
                    }}
                    onMouseDown={onResendMouseDownHandler}
                  >
                    <FormattedMessage
                      id="verification.form.resend.action"
                      defaultMessage="Resend"
                    />
                  </span>
                </>
              );
            }}
          </Mutation>
        </p>
        <Button
          isColor="info"
          className="VerificationForm__form__submit"
          onClick={handleSubmit}
          disabled={disableVerifyButton}
          isLoading={isLoading}
          type="submit"
        >
          <FormattedMessage
            id="verification.form.verify"
            defaultMessage="Verify"
          />
        </Button>
        <ErrorDialog active={isError} />
      </form>
    );
  },
);

VerificationForm.displayName = 'VerificationForm';

export default injectIntl(VerificationForm, { forwardRef: true });
