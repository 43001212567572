import * as React from 'react';
import { useEffect, useState } from 'react';
import { Columns, Column } from 'bloomer';
import { ApolloQueryResult } from 'apollo-client';
import Container from '../molecules/Container';
import Header from '../molecules/header';
import Footer from '../molecules/Footer';
import RegistrationForm from '../organisms/registrationPage/RegistrationForm';
import { GET_REGISTRATION_DATA } from '../apollo/queries/RegistrationData';
import ApiClient from '../apollo/ApiClient';
import {
  CountryCodeProps,
  RegistrationData,
  QueryAttributes,
} from '../../interfaces/Registration';
import CountrySelector from '../atoms/registrationPage/CountrySelector';
import { countryOptions } from '../lib/Helpers';
import { Config } from '../../config';
import { cookies } from '../lib/Storage';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import {
  logFirebaseEvent,
  trackRegisterUrlFrom,
} from '../../utils/FirebaseEventTracker';
import { getBaseUrl } from '../../utils/isInternalLink';
import { setLoginVisitEvent } from '../../utils/DataLayer';

const AVAILABLE_COUNTRIES = ['id', 'ph'];

import './Registration.scss';

const RegistrationPage: React.FC<CountryCodeProps & WrappedComponentProps> = ({
  countryCode,
  intl,
}: CountryCodeProps & WrappedComponentProps): React.ReactElement | null => {
  const [queryParams, setQueryParams] = useState<string | null>(null);
  const [registrationData, setRegistrationData] =
    useState<QueryAttributes | null>(null);

  document.title = intl.formatMessage({
    id: 'page_title.sign_up',
    defaultMessage: 'Sign up | Quipper',
  });

  const token = cookies.get(Config.authTokenCookieName);
  const loginReturnUrl = getBaseUrl(cookies.get('loginReturnUrl')) || '';

  useEffect((): void => {
    if (trackRegisterUrlFrom.includes(loginReturnUrl)) {
      logFirebaseEvent('REGISTRATION_OPEN');
      cookies.set('trackRegistrationFrom', true);
    } else {
      cookies.remove('trackRegistrationFrom');
    }
    // set Global DataLayer Event
    setLoginVisitEvent(countryCode);
  }, []);

  // Populate Form with Data from Query Params (Encoded JSON String)
  useEffect((): void => {
    setQueryParams(
      new URLSearchParams(window.location.search).get('data') || '',
    );

    if (!!queryParams) {
      ApiClient.query({
        query: GET_REGISTRATION_DATA,
        variables: {
          data: queryParams,
        },
      })
        .then((result: ApolloQueryResult<RegistrationData>): void => {
          const { data } = result;
          setRegistrationData(data.registrationData);
        })
        .catch((): string => (window.location.href = '/invalid'));
    }
  }, [queryParams]);

  if (!AVAILABLE_COUNTRIES.includes(countryCode as string)) {
    countryCode = 'id';
    window.history.pushState(null, document.title, '/signup/id');
  }

  if (!!token) {
    window.location.assign(Config.learnUrl);
    return null;
  }

  const countryCodeParam =
    !!registrationData &&
    registrationData.countryCode &&
    registrationData.countryCode
      ? registrationData.countryCode.toLowerCase()
      : undefined;
  const defaultCountryCode = !!countryCodeParam
    ? (countryCode = countryCodeParam as 'id' | 'ph' | undefined)
    : countryCode;

  return (
    <div className="Registration">
      <Header>
        <CountrySelector
          name="country"
          defaultValue={defaultCountryCode}
          options={countryOptions}
          disabled={!!queryParams}
        />
      </Header>

      <Container className="Registration__section">
        <Columns>
          <Column
            isSize={{ tablet: 10, desktop: 8, widescreen: 8 }}
            isOffset={{ tablet: 1, desktop: 2, widescreen: 2 }}
          >
            <RegistrationForm
              countryCode={countryCode}
              defaultValues={registrationData}
            />
          </Column>
        </Columns>
      </Container>

      <Footer />
    </div>
  );
};

export default injectIntl(RegistrationPage);
