import { Config } from '../config';
import TagManager from 'react-gtm-module';
import { cookies } from '../components/lib/Storage';
import { ApiUserData } from '../interfaces/Registration';

TagManager.initialize({ gtmId: Config.gtmId });

const PushDataLayer = (event: string): void => {
  TagManager.dataLayer({
    dataLayer: { event: event },
  });
};

export const PushDataLayerWithParams = (event: object): void => {
  TagManager.dataLayer({
    dataLayer: event,
  });
};

export const UnshiftDataLayerWithParams = (event: any): void => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.unshift(event);
};

export const setLoginVisitEvent = (country: string | undefined): void => {
  const event = {
    user_logged_in: false,
    user_language: cookies.get('language') || 'en',
    user_country: country ?? null,
  };

  UnshiftDataLayerWithParams(event);
};

export const setLoginEvent = (user: ApiUserData): void => {
  const event = {
    user_logged_in: true,
    user_id: user.id,
    user_country: user.countryCode ?? null,
    user_language: cookies.get('language') || 'en',
    user_grade: user.gradeLevel,
  };

  UnshiftDataLayerWithParams(event);
};

export default PushDataLayer;
