import * as React from 'react';
import { useIntl } from 'react-intl';
import BaseTemplate from '../templates/BaseTemplate';
import Config from '../../config';
import SoftUpdateImg from '../../assets/images/update-app.svg';

import './SoftUpdatePage.scss';

const SoftUpdatePage: React.FC = () => {
  const { formatMessage } = useIntl();

  const renderBody = (
    <div className="SoftUpdatePage">
      <h3>
        {formatMessage({
          id: 'soft_update.title',
        })}
      </h3>
      <img src={SoftUpdateImg} />
      <p>
        {formatMessage({
          id: 'soft_update.description',
        })}
      </p>

      <a href={Config.googlePlayUrl} className="Button">
        {formatMessage({
          id: 'soft_update.button',
        })}
      </a>
    </div>
  );

  return <BaseTemplate body={renderBody} />;
};

export default SoftUpdatePage;
