import { cookies } from '../components/lib/Storage';

import { shouldPolyfill as shouldPolyfillCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillRtf } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPr } from '@formatjs/intl-pluralrules/should-polyfill';

// flatten object from translattion
export const flattenMessagesObject = (
  nestedMessages: object = {},
  prefix = '',
): object => {
  if (nestedMessages == null) {
    return {};
  }

  return Object.keys(nestedMessages).reduce((messages, key): object => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string' && value.length > 0) {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessagesObject(value, prefixedKey));
    }

    return messages;
  }, {});
};

const cookieLocale = (): string => {
  //i18next is used in old qlearn-web.
  const language = cookies.get('language') || cookies.get('i18next') || 'en';
  return ['en', 'id', 'ja'].includes(language) ? language : 'en';
};

const polyfillCanonical = async (): Promise<void> => {
  if (shouldPolyfillCanonical()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }
};

const polyfillLocale = async (): Promise<void> => {
  if (shouldPolyfillLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }
};

export const polyfillPr = async (locale: string): Promise<void> => {
  const unsupportedLocale = shouldPolyfillPr(locale);

  if (!unsupportedLocale) {
    return;
  }

  await import('@formatjs/intl-pluralrules/polyfill-force');
  await import(`@formatjs/intl-pluralrules/locale-data/${unsupportedLocale}`);
};

export const polyfillRtf = async (locale: string): Promise<void> => {
  const unsupportedLocale = shouldPolyfillRtf(locale);

  if (!unsupportedLocale) {
    return;
  }

  await import('@formatjs/intl-relativetimeformat/polyfill-force');
  await import(
    `@formatjs/intl-relativetimeformat/locale-data/${unsupportedLocale}`
  );
};

const loadLocaleData = (): void => {
  const locale = cookieLocale().split('-')[0];

  polyfillCanonical();
  polyfillLocale();
  polyfillPr(locale);
  polyfillRtf(locale);
};

const messageFor = (lang: string): object => {
  return require(`../translations/${lang}`);
};

interface I18nType {
  currentLocale(): string;
  message(): Record<string, string>;
}

const I18n: I18nType = ((): I18nType => {
  return {
    currentLocale: (): string => {
      return cookieLocale();
    },

    message: (): Record<string, string> => {
      loadLocaleData();
      const messages = {
        ...flattenMessagesObject(messageFor('en-base')),
        ...flattenMessagesObject(messageFor(cookieLocale())),
      };
      return messages;
    },
  };
})();

export default I18n;
