import * as React from 'react';
import classNames from 'classnames';
import { Container as BloomerContainer } from 'bloomer';

import './Container.scss';

interface Props {
  className?: string;
  isCompact?: boolean;
  children: React.ReactNode;
}

const Container = ({
  children,
  isCompact,
  className,
}: Props): React.ReactElement => {
  const containerClassName = classNames([
    {
      'is-compact': isCompact,
    },
    {
      [`${className}`]: className,
    },
  ]);

  return (
    <BloomerContainer className={containerClassName}>
      {children}
    </BloomerContainer>
  );
};

Container.defaultProps = { withPadding: true };

export default Container;
