import * as React from 'react';
import { useState, useEffect } from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { FacebookField } from '../../../interfaces/Registration';
import { Button } from 'bloomer';
import { Config } from '../../../config';
import Icon from '../../atoms/Icon';

import './FacebookButton.scss';
import gtmEvent from '../../../utils/GtmEvent';

declare global {
  interface Window {
    fbAsyncInit: () => void;
    FB: fb.FacebookStatic;
  }
}

let facebookProfile = {};

interface FacebookButtonProps {
  facebookCallback: (profile: FacebookField) => void;
}

const FacebookButton: React.FC<FacebookButtonProps & WrappedComponentProps> = ({
  facebookCallback,
  intl,
}: FacebookButtonProps & WrappedComponentProps): React.ReactElement => {
  const [facebookError, setFacebookError] = useState(false);
  useEffect((): void => {
    window.fbAsyncInit = (): void => {
      window.FB.init({
        appId: Config.facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v16.0',
      });
    };

    const script = document.createElement('script');

    script.id = 'facebook-jssdk';
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  const fetchDataFacebook = (): void => {
    window.FB.api(
      '/me',
      { fields: 'id, email, first_name, last_name' },
      (user: string): void => {
        facebookProfile['id'] = user['id'];
        facebookProfile['email'] = user['email'];
        facebookProfile['firstName'] = user['first_name'];
        facebookProfile['lastName'] = user['last_name'];
        facebookCallback(facebookProfile);
        facebookProfile = {};
      },
    );
  };

  const statusChangeCallback = (response: fb.StatusResponse): void => {
    if (response.status === 'connected') {
      facebookProfile['facebookToken'] =
        response['authResponse']['accessToken'];
      fetchDataFacebook();
    } else if (response.status === 'not_authorized') {
      setFacebookError(true);
      console.log('Import error', 'Authorize app to import data', 'error');
    } else {
      console.log(
        'Import error',
        'Error occured while importing data',
        'error',
      );
    }
  };

  const facebookLogin = (): void => {
    window.FB?.login(
      (response): void => {
        statusChangeCallback(response);
      },
      { scope: 'email,public_profile' },
    );
    gtmEvent({
      event: 'signup',
      method: 'facebook',
    });
  };

  if (facebookError) {
    return (
      <div className="facebook-error">
        <p>
          {intl.formatMessage({
            id: 'registration.facebook.error',
            defaultMessage: 'Sorry, Facebook is currently unavailable.',
          })}
        </p>
      </div>
    );
  }
  return (
    <Button className="fb-button" isFullWidth onClick={facebookLogin}>
      <Icon icon="facebook" />
      {intl.formatMessage({
        id: 'registration.facebook.signup_button',
        defaultMessage: 'Sign Up with Facebook',
      })}
    </Button>
  );
};

export default injectIntl(FacebookButton);
