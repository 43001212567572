import * as React from 'react';

import './Tooltip.scss';

interface Props {
  // TODO: add position style, length and size
  text: string;
  children: React.ReactNode;
}
const Tooltip = ({ text, children }: Props): React.ReactElement => (
  <span className="Tooltip" data-tooltip={text}>
    {children}
  </span>
);

export default Tooltip;
