import React, { useState, useRef, useEffect } from 'react';
import { ApolloError } from 'apollo-client';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import BaseTemplate from '../../templates/BaseTemplate';
import Header from '../../molecules/header';
import Footer from '../../molecules/Footer';
import Container from '../../molecules/Container';
import { Columns, Column } from 'bloomer';
import VerificationSuccess from './VerificationSuccess';
import VerificationForm from './VerificationForm';
import { VerificationStatus } from '../__mocks__/MockVerification';
import { Mutation } from 'react-apollo';
import { VERIFY_EMAIL } from '../../apollo/mutations/Verification';
import { Config } from '../../../config';
import { cookies } from '../../lib/Storage';
import { isStringOrNumber } from '../../lib/Validator';
import { UserDataInterface } from './interfaces/verification';
import ErrorDialog from '../../molecules/ErrorDialog';
import { clearCountdown } from '../../lib/Helpers';
import { logFirebaseEvent } from '../../../utils/FirebaseEventTracker';

import './VerificationPage.scss';

interface Props {
  tokenId?: string;
  location?: {
    state: UserDataInterface;
  };
}

const defaultProps: UserDataInterface = {
  email: '',
  password: '',
  registeredFrom: '',
  countryCode: '',
  organizationMembershipCode: '',
  grade: '',
};

const VerificationPage = ({
  intl,
  ...props
}: Props & WrappedComponentProps): React.ReactElement => {
  document.title = intl.formatMessage({
    id: 'page_title.email_verification',
    defaultMessage: 'Email Verification | Quipper',
  });

  const [inputsValue, setInputsValue] = useState<string>('');
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [codeStatus, setCodeStatus] = useState<VerificationStatus>(
    VerificationStatus.Default,
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [verificationError, setVerificationError] = useState<string>('');
  let userData = props && props.location ? props.location.state : defaultProps;

  if (!userData) {
    const userEmailVerification = cookies.get('user_email_verification');

    userData = {
      ...defaultProps,
      ...userEmailVerification,
    };
  }

  const handleChange =
    (index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const nextInputElement: HTMLInputElement = inputRefs.current[index + 1];
      const currentValue = e.target.value;
      const convertInputsToString = inputRefs.current.reduce(
        (prevInput, currInput) => String(prevInput + currInput.value),
        '',
      );

      if (nextInputElement && currentValue) {
        nextInputElement.focus();
        nextInputElement.select();
      }

      setInputsValue(convertInputsToString);
      setCodeStatus(VerificationStatus.Default);
      setIsFormSubmitted(false);
    };

  const handleKeyDown =
    (index: number) =>
    (e: React.KeyboardEvent<HTMLInputElement>): void => {
      const keyPressed = e.key;
      const currentValue = (e.target as HTMLInputElement).value;
      const prevInputElement: HTMLInputElement = inputRefs.current[index - 1];

      if (
        prevInputElement &&
        !currentValue &&
        (keyPressed === 'Backspace' || keyPressed === 'Delete')
      ) {
        prevInputElement.focus();
        prevInputElement.select();
      }
    };

  const handlePaste = (e: React.ClipboardEvent): void => {
    const clipText = e.clipboardData.getData('text').trim();

    if (!(isStringOrNumber(clipText).valid && clipText.length === 6)) {
      return e.preventDefault();
    }

    const refs = inputRefs.current;

    refs.forEach((ref, i) => {
      ref.value = clipText[i];
    });

    refs[refs.length - 1].focus();
    setInputsValue(clipText);
    setIsFormSubmitted(false);
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (codeStatus !== VerificationStatus.Default && isFormSubmitted) {
      const lastInput = inputRefs.current[inputRefs.current.length - 1];
      lastInput.focus();
      lastInput.select();
    }
  }, [codeStatus, isFormSubmitted]);

  const handleErrors = (error: ApolloError): void => {
    const errorMessage =
      (error &&
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].extensions &&
        error.graphQLErrors[0].extensions.body &&
        Array.isArray(error.graphQLErrors[0].extensions.body) &&
        error.graphQLErrors[0].extensions.body[0] &&
        error.graphQLErrors[0].extensions.body[0].code) ||
      '';

    switch (errorMessage) {
      case 'is expired':
        setCodeStatus(VerificationStatus.Expired);
        return;
      case 'is invalid':
        setCodeStatus(VerificationStatus.Invalid);
        return;
      case 'not found':
        setIsError(true);
        setVerificationError('verificationNotFound');
      default:
        setIsError(true);
    }
  };

  return (
    <section className="VerificationPage">
      <BaseTemplate
        header={<Header />}
        body={
          <div>
            <Container isCompact>
              <Columns>
                <Column isSize={10} isOffset={1}>
                  <div className="VerificationPage__wrapper">
                    <Mutation
                      mutation={VERIFY_EMAIL}
                      onError={(): void => {
                        /* empty handler to prevent thrown errors */
                      }}
                    >
                      {(
                        verifyEmail: any,
                        { loading, error, data }: any,
                      ): React.ReactElement => {
                        if (loading) {
                          setIsSubmitLoading(true);
                        }

                        if (error && isFormSubmitted) {
                          setIsSubmitLoading(false);
                          handleErrors(error);
                        }

                        if (data) {
                          clearCountdown();
                          cookies.remove('user_email_verification', {
                            path: '/',
                            domain: Config.cookieDomain,
                            sameSite: 'lax',
                          });

                          if (cookies.get('trackRegistrationFrom')) {
                            logFirebaseEvent('REGISTRATION_COMPLETED');
                            cookies.remove('loginReturnUrl', {
                              path: '/',
                              domain: Config.cookieDomain,
                              sameSite: 'lax',
                            });
                          }
                          const accessToken = data.verifyEmail.accessToken;
                          const previousUrl = cookies.get('previousUrl');

                          accessToken &&
                            cookies.set(
                              Config.authTokenCookieName,
                              accessToken,
                              {
                                path: '/',
                                domain: Config.cookieDomain,
                              },
                            );

                          return (
                            <VerificationSuccess
                              redirectUrl={
                                data.verifyEmail.redirectUrl ||
                                previousUrl ||
                                Config.learnUrl
                              }
                            />
                          );
                        }

                        if (props.tokenId && !isFormSubmitted) {
                          setIsFormSubmitted(true);
                          verifyEmail({
                            variables: {
                              email: null,
                              code: null,
                              token: props.tokenId,
                            },
                          });
                        }

                        return (
                          <VerificationForm
                            handleSubmit={(e) => {
                              e.preventDefault();
                              setIsFormSubmitted(true);
                              verifyEmail({
                                variables: {
                                  code: inputsValue.toUpperCase(),
                                  email: userData?.email,
                                  token: props.tokenId,
                                },
                              });
                            }}
                            handleChange={handleChange}
                            disableVerifyButton={inputsValue.length < 6}
                            ref={inputRefs}
                            handleKeyDown={handleKeyDown}
                            handlePaste={handlePaste}
                            codeStatus={codeStatus}
                            isFormSubmitted={isFormSubmitted}
                            isLoading={isSubmitLoading}
                            userData={userData}
                          />
                        );
                      }}
                    </Mutation>
                  </div>
                </Column>
              </Columns>
            </Container>
            <ErrorDialog
              active={isError}
              errorString={verificationError ? verificationError : ''}
            />
          </div>
        }
        footer={<Footer />}
      />
    </section>
  );
};

export default injectIntl(VerificationPage);
