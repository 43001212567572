import * as React from 'react';
import { Router, Redirect } from '@reach/router';
import RegistrationPage from './pages/RegistrationPage';
import NotFoundPage from './pages/NotFoundPage';
import SoftUpdatePage from './pages/SoftUpdatePage';
import MaintenancePage from './pages/MaintenancePage';
import InvalidPage from './pages/InvalidPage';
import Browser from '../utils/Browser';
import VerificationPage from './pages/verification';

const Root = (): React.ReactElement => {
  if (Browser.isIE()) {
    return (
      <MaintenancePage
        title={'noie.title'}
        content={'noie.content'}
        isIE={true}
      />
    );
  }

  return (
    <Router>
      <NotFoundPage path="/" default />
      <Redirect from="/" to="signup" noThrow />
      <RegistrationPage path="signup" />
      <RegistrationPage path="signup/:countryCode" />
      <SoftUpdatePage path="embed/update" />

      <VerificationPage path="verification" />
      <VerificationPage path="verification/:tokenId" />

      <MaintenancePage path="maintenance" />
      <InvalidPage path="invalid" />
      <NotFoundPage path="*" default />
    </Router>
  );
};

export default Root;
