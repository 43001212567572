import * as React from 'react';
import { Link } from '@reach/router';
import { isInternalLink } from '../../../utils/isInternalLink';
import classNames from 'classnames';
import Container from '../../molecules/Container';

import './HeaderTitle.scss';

interface Props {
  title: string;
  className?: string;
  backUrl?: string;
  backText?: string;
  children?: React.ReactNode | React.ReactNode[];
  hideBackBtn?: boolean;
}

const HeaderTitle: React.FC<Props> = ({
  title,
  className,
  backUrl,
  backText,
  children,
  hideBackBtn,
}: Props): React.ReactElement => {
  const headerTitleClassNames = classNames(
    'HeaderTitle',
    {
      [`${className}`]: className,
    },
    {
      'HeaderTitle--bold': backUrl,
    },
  );

  return (
    <div className={headerTitleClassNames}>
      <Container isCompact>
        <section>
          <h2>
            {backUrl && !hideBackBtn && (
              <span className="HeaderTitle__back">
                {isInternalLink(backUrl) ? (
                  <Link to={backUrl}>{backText ? backText : 'Back'}</Link>
                ) : (
                  <a href={backUrl}>{backText ? backText : 'Back'}</a>
                )}
              </span>
            )}
            {title ? title : 'Title'}
          </h2>
        </section>
        {children && <section>{children}</section>}
      </Container>
    </div>
  );
};

HeaderTitle.defaultProps = { backText: 'Back' };

export default HeaderTitle;
