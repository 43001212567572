export default class Browser {
  public static getDomain(host: string = location.host): string {
    const ipv4Regex = /^(\d{1,3}\.){3,3}\d{1,3}$/;
    const domain = host.split(':')[0]; // remove port
    const parts = domain.split('.');

    if (parts.length === 1 || ipv4Regex.test(domain)) {
      return domain;
    }

    // productName.secondLevelDomain.firstLevelDomain.topLevelDomain
    // e.g. learn-beta.aya.quipper.net
    const topLevelDomain = parts[parts.length - 1];
    const firstLevelDomain = parts[parts.length - 2];
    let domainParts = [firstLevelDomain, topLevelDomain];

    if (parts.length > 3) {
      // i.e. there's a secondLevelDomain
      const secondLevelDomain = parts[parts.length - 3];
      domainParts = [secondLevelDomain, ...domainParts];
    }

    return domainParts.join('.');
  }

  // to stub window.location easily
  public static pathname(): string {
    return window.location.pathname;
  }

  // to stub window.location easily
  public static reload(): void {
    return window.location.reload();
  }

  // to stub window.location easily
  public static host(): string {
    return window.location.host;
  }

  public static isIE(): boolean {
    // [bowser](https://github.com/lancedikson/bowser) is too much for now https://stackoverflow.com/a/9851769/225291
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - documentMode is only IE
    return /*@cc_on!@*/ !!document.documentMode;
  }

  public static isEdge(): boolean {
    return window.navigator.userAgent.indexOf('Edge') > -1;
  }

  public static isAndroidVersionLessThan5(): boolean {
    const ua = window.navigator.userAgent;
    const match = ua.match(/Android\s([0-9.]*)/i);
    if (!match) {
      return false;
    }
    return parseInt(match[1], 10) < 5;
  }

  public static isAndroidDefaultBrowser(): boolean {
    const ua = window.navigator.userAgent;
    // borrowed code from here: https://gist.github.com/bissy/108e49118629e398be677999bcdb4484
    // probably this doesn't cover all the case
    return (
      (/Android/.test(ua) && /Linux; U;/.test(ua) && !/Chrome/.test(ua)) ||
      (/Android/.test(ua) && /Chrome/.test(ua) && /Version/.test(ua)) ||
      (/Android/.test(ua) && /Chrome/.test(ua) && /SamsungBrowser/.test(ua))
    );
  }

  private static isIOSApp = (): boolean => {
    const ua = window.navigator.userAgent;
    const regexp = new RegExp(/QLearniOS/);
    return regexp.test(ua);
  };

  private static isAndroidApp = (): boolean => {
    const ua = window.navigator.userAgent;
    const regexp = new RegExp(/QLearnAndroid/);
    return regexp.test(ua);
  };

  public static isNativeApp = (): boolean => {
    return Browser.isIOSApp() || Browser.isAndroidApp();
  };

  // https://bugs.chromium.org/p/chromium/issues/detail?id=926865
  public static hasDocumentTitleBugOnPathChange = (): boolean => {
    return Browser.isIOSChrome();
  };

  private static isIOSChrome = (): boolean => {
    const ua = window.navigator.userAgent;
    const regexp = new RegExp(/CriOS/);
    return regexp.test(ua);
  };

  public static isIOSVersionLessThan11 = (): boolean | null => {
    const ua = window.navigator.userAgent;
    if (!/iP(hone|od|ad)/.test(ua)) {
      return false;
    }

    const version = ua.match(/OS (\d+)_\d+_?\d+?/);

    return version && parseInt(version[1], 10) < 11;
  };
}
