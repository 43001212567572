import * as React from 'react';
import classNames from 'classnames';
import { colorTypes } from '../types';

import './Button.scss';

interface ButtonProps {
  isText?: boolean;
  isFullwidth?: boolean;
  className?: string;
  color?: colorTypes;
  disabled?: boolean;
  onClick?: () => void;
  children: string | React.ReactNode | React.ReactNode[];
}

const Button: React.FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
  isText,
  isFullwidth,
  className,
  color,
  onClick,
  disabled,
  children,
  ...attr
}: ButtonProps): React.ReactElement => {
  const buttonClassName = classNames([
    'Button',
    { 'Button--disabled': disabled },
    { [`${className}`]: className },
    { [`Button--${color}`]: color && !isText },
    { [`Button--text-${color}`]: isText },
    { ['Button--is-fullwidth']: isFullwidth },
  ]);

  return (
    <button
      {...attr}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
